import React, { useCallback, useState } from 'react';
import { Spinner } from '@gsa/afp-component-library';
import { useQuery } from '@apollo/client';
import { GET_MAKES_AND_MODELS } from '../queries.gql';
import TableFrame from './make-model-table-frame';
import MakeModelDetailsRow from './make-model-detail-row';

export default () => {
  const itemsPerPageOptions = [50, 100, 150, 200];
  const initialPaginationState = {
    limit: itemsPerPageOptions[0],
    offset: 0,
    currentPage: 1,
    isReset: false,
  };

  const [paginationState, setPaginationState] = useState(
    initialPaginationState,
  );

  const [queryFiltersState, setQueryFiltersState] = useState({
    operator: '$and',
    conditions: [
      {
        operator: '$in',
        key: 'fleetStatus',
        value: ['1', '2'],
      },
    ],
  });
  const [order, setOrder] = useState('make.makeName ASC');

  const tableRef = React.createRef();
  const { data, isLoading, refetch: refetchMakeModel } = useQuery(
    GET_MAKES_AND_MODELS,
    {
      variables: {
        offset: (paginationState.currentPage - 1) * paginationState.limit,
        limit: paginationState.limit,
        order,
        ...(queryFiltersState ? { filters: queryFiltersState } : {}),
      },
    },
  );

  const { rows = [], count = 0 } = data?.getFilteredModels || {};

  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    const { makeCode, modelCode, fleetComment, fleetStatus } = original;
    return (
      <MakeModelDetailsRow
        comment={fleetStatus === 'Rejected' ? fleetComment : ''}
        makeCode={makeCode}
        modelCode={modelCode}
      />
    );
  }, []);

  const handleFilterChange = useCallback((updatedFilters) => {
    setPaginationState({
      ...paginationState,
      currentPage: 1,
    });
    setQueryFiltersState({
      operator: '$and',
      conditions: updatedFilters,
    });
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  const onSort = (val) => {
    setOrder(val);
    refetchMakeModel({
      offset: (paginationState.currentPage - 1) * paginationState.limit,
      limit: paginationState.limit,
      order: val,
      ...(queryFiltersState ? { filters: queryFiltersState } : {}),
    });
  };

  return (
    <TableFrame
      filterToggle
      lowLeftProps={{
        handleFilterChange,
      }}
      lowRightProps={{
        tableProps: {
          expandable: true,
          ref: tableRef,
          data: rows,
          count,
          renderRowSubComponent,
          refetchMakeModel,
          onSort,
          defaultSort: order,
        },
        paginationState,
        setPaginationState,
      }}
    />
  );
};
