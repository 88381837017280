import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SelectDropdown, Button } from '@gsa/afp-component-library';

import './contract-line-listing-search.scss';
import classNames from 'classnames';

const NO_VAL = '';
const EMPTY_OPTION = { label: '-Select-', value: NO_VAL };

export const DEFAULT_FILTERS = {
  scheduleLine: '',
  makeName: '',
  modelName: '',
  chassisMakeName: '',
  chassisModelName: '',
  modelYear: '',
  manufacturerModelNumber: '',
};

const FILTER_OPTIONS = {
  scheduleLine: [EMPTY_OPTION],
  makeName: [EMPTY_OPTION],
  modelName: [EMPTY_OPTION],
  chassisMakeName: [EMPTY_OPTION],
  chassisModelName: [EMPTY_OPTION],
  modelYear: [EMPTY_OPTION],
  manufacturerModelNumber: [EMPTY_OPTION],
};

const getFilterOptions = (field, contractLines) => {
  const options = contractLines.map((line) => {
    switch (field) {
      case 'makeName':
        return {
          label: line.make?.makeName,
          value: line.make?.makeCode,
        };
      case 'modelName':
        return {
          label: line.model?.modelName,
          value: line.model?.modelCode,
        };
      case 'chassisMakeName':
        return {
          label: line.chassisMake?.makeName,
          value: line.chassisMake?.makeCode,
        };
      case 'chassisModelName':
        return {
          label: line.chassisModel?.modelName,
          value: line.chassisModel?.modelCode,
        };
      default:
        return {
          label: line[field],
          value: line[field],
        };
    }
  });

  const filteredOptions = options.filter((option) => !!option.value);

  const uniqueOptions = [
    ...new Map(filteredOptions.map((item) => [item.value, item])).values(),
  ];

  return [EMPTY_OPTION, ...uniqueOptions];
};

const ContractLineListingFilter = ({
  contractLines,
  filters,
  setFilters,
  setFilteredContractLines,
  isSop,
}) => {
  const [filterOptions, setFilterOptions] = useState(FILTER_OPTIONS);
  const [isHidden, setIsHidden] = useState(false);

  // Set filter options to data values
  useEffect(() => {
    if (!contractLines?.length) {
      return;
    }
    setFilteredContractLines(contractLines);

    const newFilterOptions = {
      ...filterOptions,
      scheduleLine: getFilterOptions('scheduleLine', contractLines),
      makeName: getFilterOptions('makeName', contractLines),
      modelName: getFilterOptions('modelName', contractLines),
      chassisMakeName: getFilterOptions('chassisMakeName', contractLines),
      chassisModelName: getFilterOptions('chassisModelName', contractLines),
      ...(isSop
        ? { modelYear: getFilterOptions('modelYear', contractLines) }
        : {
            manufacturerModelNumber: getFilterOptions(
              'manufacturerModelNumber',
              contractLines,
            ),
          }),
    };

    setFilterOptions(newFilterOptions);
  }, [contractLines]);

  const applyFilters = () => {
    if (Object.values(filters).every((value) => value === '')) {
      setFilteredContractLines(contractLines);
    }

    const newFilteredContractLines = contractLines.filter((line) => {
      return Object.entries(filters).every(([key, value]) => {
        if (value === '') {
          return true;
        }
        switch (key) {
          case 'makeName':
            return line.make?.makeCode === value;
          case 'modelName':
            return line.model?.modelCode === value;
          case 'chassisMakeName':
            return line.chassisMake?.makeCode === value;
          case 'chassisModelName':
            return line.chassisModel?.modelCode === value;
          case 'manufacturerModelNumber':
            return line.manufacturerModelNumber === value;
          default:
            return String(line[key])
              .toLowerCase()
              .includes(value.toLowerCase());
        }
      });
    });

    setFilteredContractLines(newFilteredContractLines);
  };

  const onFilterChange = (key, val) => {
    setFilters({ ...filters, [key]: val });
  };

  const resetFilters = () => {
    setFilters(DEFAULT_FILTERS);
    setFilteredContractLines(contractLines);
  };

  const getSingleSelect = (field, label) => {
    return (
      <div
        className={
          field === 'manufacturerModelNumber'
            ? 'col-2 contract-line-listing-search'
            : `grid-col contract-line-listing-search`
        }
      >
        <SelectDropdown
          data-testid={`${field}-filter`}
          label={label}
          value={filters[field]}
          options={filterOptions[field]}
          onChange={({ target: { value } }) => onFilterChange(field, value)}
        />
      </div>
    );
  };

  return (
    <div className="contract-line-listing-search-block padding-2 bg-primary-lighter">
      <div className="display-flex flex-row flex-justify">
        <div className="contract-line-listing-search-header">Filters</div>
        <Button
          label={isHidden ? 'Show filters' : 'Hide filters'}
          variant="outline"
          size="small"
          onClick={() => {
            setIsHidden(!isHidden);
          }}
          leftIcon={{ name: 'filter_alt' }}
        />
      </div>
      <div
        className={classNames('contract-line-listing-search-content', {
          hidden: isHidden,
        })}
        aria-hidden={isHidden}
      >
        <div className="contract-line-listing-search-description display-flex flex-row flex-justify">
          <div>Apply at least one filter to see your results.</div>
          <div className="contract-line-listing-search-reset">
            <Button
              variant="unstyled"
              label="Reset all filters"
              onClick={resetFilters}
              leftIcon={{ name: 'close' }}
            />
          </div>
        </div>
        <div className="grid-row grid-gap">
          {getSingleSelect('scheduleLine', 'Contract line')}
          {getSingleSelect('makeName', 'Body make')}
          {getSingleSelect('modelName', 'Body model')}
          {getSingleSelect('chassisMakeName', 'Chassis make')}
          {getSingleSelect('chassisModelName', 'Chassis model')}
          {isSop
            ? getSingleSelect('modelYear', 'Model year')
            : getSingleSelect(
                'manufacturerModelNumber',
                'Manufacturer model number',
              )}
        </div>
        <div className="contract-line-listing-search-apply display-flex flex-row flex-justify">
          <Button label="Apply filters" onClick={() => applyFilters()} />
        </div>
      </div>
    </div>
  );
};

ContractLineListingFilter.propTypes = {
  contractLines: PropTypes.shape(Array).isRequired,
  filters: PropTypes.shape(Object).isRequired,
  setFilters: PropTypes.func.isRequired,
  setFilteredContractLines: PropTypes.func.isRequired,
  isSop: PropTypes.bool.isRequired,
};

export default ContractLineListingFilter;
