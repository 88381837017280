import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typeahead, FilterPanel } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { GET_CHASSIS_MODELS_BY_PARTIAL_MATCH_FROM_APPROVAL } from '../queries.gql';

export const modelTypeAheadValue = (modelName, modelCode) => {
  return [modelName, modelCode].join(' - ');
};

const { useFilterPanel } = FilterPanel;

let globalMakeCode = '';

const ChassisModelTypeAhead = ({ filter }) => {
  const {
    setFilters: setFilterPanelFilters,
    clearOneFilter,
  } = useFilterPanel();

  const { key, placeholder, ariaLabel, customFieldProps } = filter || {};
  const { inputCharNum, makeCode } = customFieldProps || {};
  const [suggestions, setSuggestions] = useState([]);
  const [values, setValues] = useState([]);
  const [, setError] = useState(null);
  const [searchValue] = useState('');

  useEffect(() => {
    globalMakeCode = makeCode;
  }, [makeCode]);

  const [getModelsByPartialName] = useLazyQuery(
    GET_CHASSIS_MODELS_BY_PARTIAL_MATCH_FROM_APPROVAL,
    {
      onCompleted: (data) => {
        const uniqueData = data.getModelsByPartialMatchFromApproval.filter(
          (value, index, self) =>
            self.findIndex(
              (v) => v?.chassisModelCode === value?.chassisModelCode,
            ) === index,
        );
        setSuggestions(uniqueData);
        setValues(
          uniqueData?.map(
            (m) =>
              `${m?.chassisModel?.modelName} - ${m?.chassisModel?.modelCode}`,
          ),
        );
      },
      onError: () => {
        setError('Unable to fetch model suggestions.');
      },
    },
  );

  const fetchValues = (_, value) => {
    getModelsByPartialName({
      variables: {
        makeCode: parseInt(globalMakeCode, 10),
        modelName: value?.split(' - ')[0],
        dataSource: 'AFP',
      },
    });
  };

  const handleTypeAheadClear = () => {
    clearOneFilter(key);
    setFilterPanelFilters({
      type: 'setOne',
      fetchNewData: false,
      filter: {
        ...filter,
        displayValue: () => '',
        value: '',
      },
    });
  };

  const shortenModelCode = (modelCode, max) => {
    if (modelCode.length > 10) {
      return modelCode.substring(0, max);
    }
    return modelCode;
  };

  const handleMakeSelection = (data) => {
    const {
      chassisModel: { modelName, modelCode },
    } = data || {};
    if (modelCode) {
      setFilterPanelFilters({
        type: 'setOne',
        fetchNewData: true,
        filter: {
          ...filter,
          displayValue: () =>
            `Model: ${modelName} Code: ${shortenModelCode(modelCode, 5)}` || '',

          value: modelCode,
        },
      });
    }
  };

  const generateCustomOption = (opt) => {
    const item = suggestions?.find(
      (o) => o?.chassisModel?.modelCode === opt?.split(' - ')[1],
    );
    return (
      <>
        {item ? (
          <div className="display-flex flex-column">
            <span className="text-ink">
              Make: {item?.chassisModel?.modelName}
            </span>
            <span className="text-ink">
              Code: {shortenModelCode(item?.chassisModel?.modelCode, 8)}
            </span>
          </div>
        ) : (
          opt
        )}
      </>
    );
  };

  return (
    <Typeahead
      key={key}
      filterValue={searchValue}
      placeholder={placeholder}
      ariaLabel={ariaLabel}
      onOptionEnter={(value) => {
        const segs = value.split(' - ');
        const code = segs[segs.length - 1];
        handleMakeSelection(
          suggestions.find((m) => m?.chassisModel?.modelCode === code),
        );
      }}
      onClear={handleTypeAheadClear}
      typeaheadValues={values}
      fetchTypeaheadValues={fetchValues}
      inputCharNum={inputCharNum}
      reset={!filter?.value}
      generateCustomOption={generateCustomOption}
      debounceDelay={500}
      promptText="Type to search for models"
    />
  );
};

ChassisModelTypeAhead.propTypes = {
  filter: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
    operator: PropTypes.string,
    placeholder: PropTypes.string,
    ariaLabel: PropTypes.string,
    customFieldProps: PropTypes.shape({
      inputCharNum: PropTypes.number,
      setFilters: PropTypes.func,
      currentFilters: PropTypes.shape({
        value: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string,
            operator: PropTypes.string,
            value: PropTypes.string,
          }),
        ),
      }),
    }),
  }).isRequired,
};

export default ChassisModelTypeAhead;
