import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import {
  GET_MAKES_BY_PARTIAL_MATCH,
  GET_MODELS_BY_PARTIAL_MATCH,
} from '../../make-model-approval/queries.gql';

const BidHistoryTableFilter = ({ filterStructureData, setFilters }) => {
  const [selectedMakeCode, setSelectedMakeCode] = useState('');
  // const [selectedBodyMakeCode, setSelectedBodyMakeCode] = useState('');
  const [selectedChassisMakeCode, setSelectedChassisMakeCode] = useState('');
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [chassesMakes, setChassesMakes] = useState([]);
  const [chassesModels, setChassesModels] = useState([]);
  const [typeaheadData, setTypeaheadData] = useState([]);

  const [getMakesByPartialName] = useLazyQuery(GET_MAKES_BY_PARTIAL_MATCH, {
    onCompleted: (data) => {
      const values = data?.getMakesByPartialMatch?.map(
        ({ makeName }) => makeName,
      );
      setMakes(data?.getMakesByPartialMatch);
      setTypeaheadData({ field: 'afpMake.makeCode', values });
    },
  });

  const [getModelsByPartialName] = useLazyQuery(GET_MODELS_BY_PARTIAL_MATCH, {
    onCompleted: (data) => {
      const values = data?.getModelsByPartialMatch?.map(
        ({ modelName }) => modelName,
      );
      setModels(data?.getModelsByPartialMatch);
      setTypeaheadData({ field: 'afpModel.modelCode', values });
    },
  });

  const [getChassisMakesByPartialName] = useLazyQuery(
    GET_MAKES_BY_PARTIAL_MATCH,
    {
      onCompleted: (data) => {
        const values = data?.getMakesByPartialMatch?.map(
          ({ makeName }) => makeName,
        );
        setChassesMakes(data?.getMakesByPartialMatch);
        setTypeaheadData({ field: 'chassisMake.makeCode', values });
      },
    },
  );
  const [getChassisModelsByPartialName] = useLazyQuery(
    GET_MODELS_BY_PARTIAL_MATCH,
    {
      onCompleted: (data) => {
        const values = data?.getModelsByPartialMatch?.map(
          ({ modelName }) => modelName,
        );
        setChassesModels(data?.getModelsByPartialMatch);
        setTypeaheadData({ field: 'chassisModel.modelCode', values });
      },
    },
  );

  const getUniqueFilterElements = (parentProperty, childProperty) => {
    const uniqueElements = [
      ...new Set(
        filterStructureData.map((item) => item[parentProperty][childProperty]),
      ),
    ];
    const options = uniqueElements.map((element) => ({
      value: element,
      label: element,
    }));

    if (childProperty === 'contractYear')
      options.unshift({ value: 'select', label: '-Select-' });

    return options;
  };

  const contractYears = getUniqueFilterElements('soliciation', 'contractYear');

  const solicitations = getUniqueFilterElements(
    'soliciation',
    'solicitationNumber',
  );

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const filteredSolicitationsWithDelay = async (filterObj) => {
    const lowerCaseFilterValue = filterObj.value.toLowerCase();
    const filteredData = [
      ...new Set(
        solicitations
          .filter((item) =>
            item.value.toLowerCase().includes(lowerCaseFilterValue),
          )
          .map((item) => item.value),
      ),
    ];
    await delay(50);

    return filteredData;
  };

  const filterdStandardItemsWithDelay = async (filterObj) => {
    const lowerCaseFilterValue = filterObj.value.toLowerCase();

    const filteredData = [
      ...new Set(
        filterStructureData.flatMap((item) =>
          item.BidLines.filter((bidLine) =>
            bidLine?.standardItem?.standardItemNumber
              ?.toLowerCase()
              .includes(lowerCaseFilterValue),
          ).map((bidLine) => bidLine.standardItem.standardItemNumber),
        ),
      ),
    ];

    await delay(50);

    return filteredData;
  };

  const handleFetchTypeaheads = ({ variables: queryVariables }) => {
    const selectedKey = queryVariables?.field;
    const filterObj = queryVariables?.filters[0]?.conditions?.find(
      (filter) => filter.key === selectedKey,
    );

    if (filterObj?.key === 'afpMake.makeCode') {
      getMakesByPartialName({
        variables: {
          makeName: filterObj?.value,
          dataSource: 'AFP',
        },
      });
    }

    if (filterObj?.key === 'chassisMake.makeCode') {
      getChassisMakesByPartialName({
        variables: {
          makeName: filterObj?.value,
          dataSource: 'AFP',
        },
      });
    }

    if (filterObj?.key === 'afpModel.modelCode') {
      getModelsByPartialName({
        variables: {
          makeCode: parseInt(selectedMakeCode, 10),
          modelName: filterObj?.value,
          dataSource: 'AFP',
        },
      });
    }

    if (filterObj?.key === 'chassisModel.modelCode') {
      getChassisModelsByPartialName({
        variables: {
          makeCode: parseInt(selectedChassisMakeCode, 10),
          modelName: filterObj?.value,
          dataSource: 'AFP',
        },
      });
    }

    if (filterObj?.key === 'soliciation.solicitationNumber') {
      filteredSolicitationsWithDelay(filterObj).then((result) => {
        setTypeaheadData({
          field: 'soliciation.solicitationNumber',
          values: result,
        });
      });
    }

    if (filterObj?.key === 'standardItem.standardItemNumber') {
      filterdStandardItemsWithDelay(filterObj).then((result) => {
        setTypeaheadData({
          field: 'standardItem.standardItemNumber',
          values: result,
        });
      });
    }
  };

  const handleFiltersChange = (updatedFilters) => {
    const filters = updatedFilters.flatMap((filter) => {
      if (filter?.key === 'afpMake.makeCode') {
        const makeCode = makes.find((make) => make.makeName === filter.value)
          ?.makeCode;
        setSelectedMakeCode(makeCode);
        return {
          key: 'afpMake.makeCode',
          value: makeCode,
        };
      }

      if (filter?.key === 'chassisMake.makeCode') {
        const { makeCode } = chassesMakes.find(
          (make) => make.makeName === filter.value,
        );
        setSelectedChassisMakeCode(makeCode);
        return {
          key: 'chassisMake.makeCode',
          value: makeCode,
        };
      }

      if (filter?.key === 'afpModel.modelCode') {
        const modelCode = models.find((m) => m.modelName === filter.value)
          ?.modelCode;
        return {
          key: 'afpModel.modelCode',
          value: modelCode,
        };
      }

      if (filter?.key === 'chassisModel.modelCode') {
        const modelCode = chassesModels.find(
          (m) => m.modelName === filter.value,
        )?.modelCode;
        return {
          key: 'chassisModel.modelCode',
          value: modelCode,
        };
      }

      if (filter?.key === 'soliciation.contractYear') {
        return {
          key: 'soliciation.contractYear',
          value: parseInt(filter.value, 10),
        };
      }

      if (filter?.key === 'soliciation.solicitationNumber') {
        return {
          key: 'soliciation.solicitationNumber',
          value: filter.value,
        };
      }

      if (filter?.key === 'standardItem.standardItemNumber') {
        return {
          key: 'standardItem.standardItemNumber',
          value: filter.value,
        };
      }

      return filter;
    });
    setFilters(filters);
  };

  return (
    <FilterPanel.FilterPanel
      filterStructure={[
        {
          key: 'soliciation.solicitationNumber',
          title: 'Solicitation uPIID',
          id: 'solicitationNumber',
          placeholder: 'Search solicitations',
          type: 'typeahead',
          operator: '$exact',
          value: '',
          expanded: true,
          hideClear: true,
          customFieldProps: { inputCharNum: 3 },
        },
        {
          key: 'standardItem.standardItemNumber',
          title: 'Standard Item',
          id: 'standardItemNumber',
          placeholder: 'Search standard items',
          type: 'typeahead',
          operator: '$exact',
          value: '',
          expanded: true,
          hideClear: true,
          customFieldProps: { inputCharNum: 1, clearPanelFilterOnEmpty: true },
        },
        {
          key: 'afpMake.makeCode',
          title: 'Body make',
          type: 'typeahead',
          id: 'bodyMake',
          placeholder: 'Search body make',
          operator: '$exact',
          value: '',
          expanded: true,
          hideClear: true,
          customFieldProps: { inputCharNum: 1, clearPanelFilterOnEmpty: true },
        },
        {
          key: 'afpModel.modelCode',
          title: 'Body model',
          type: 'typeahead',
          id: 'bModel',
          placeholder: 'Search body model',
          operator: '$exact',
          value: '',
          expanded: true,
          hideClear: true,
          customFieldProps: { inputCharNum: 1, clearPanelFilterOnEmpty: true },
        },
        {
          title: 'Chassis make',
          key: 'chassisMake.makeCode',
          id: 'chassisMake',
          placeholder: 'Search chassis make',
          type: 'typeahead',
          operator: '$exact',
          value: '',
          expanded: true,
          customFieldProps: {
            inputCharNum: 1,
            clearPanelFilterOnEmpty: true,
          },
        },
        {
          title: 'Chassis model',
          key: 'chassisModel.modelCode',
          id: 'chassisModel',
          placeholder: 'Search chassis model',
          type: 'typeahead',
          operator: '$exact',
          value: '',
          expanded: true,
          customFieldProps: {
            inputCharNum: 1,
            clearPanelFilterOnEmpty: true,
          },
        },
        {
          key: 'soliciation.contractYear',
          title: 'Model year',
          placeholder: '-Select-',
          type: 'select',
          options: contractYears,
          value: '',
          operator: 'EQ',
          expanded: true,
          hideClear: true,
        },
      ]}
      clearButtonLabel="Reset All"
      showClearIcon
      setQueryFiltersState={handleFiltersChange}
      model="bid"
      order={[['id', 'ASC']]}
      subTitle="Apply filters"
      titleSeparator
      showSearchIcon
      typeaheadData={typeaheadData}
      fetchTypeaheads={handleFetchTypeaheads}
    />
  );
};

BidHistoryTableFilter.propTypes = {
  filterStructureData: PropTypes.arrayOf(PropTypes.array).isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default BidHistoryTableFilter;
