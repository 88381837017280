import React from 'react';
import { Spinner } from '@gsa/afp-component-library';
import { PropTypes } from 'prop-types';
import { useQuery } from '@apollo/client';
import { GET_BID_LINES_BY_MAKE_MODEL } from '../queries.gql';
import { emDashUnicode } from '../../../utilities/constants';

const MakeModelApprovalDetailsRow = ({
  comment,
  makeCode,
  modelCode,
  chassisMakeCode,
  chassisModelCode,
}) => {
  const { data, isLoading } = useQuery(GET_BID_LINES_BY_MAKE_MODEL, {
    variables: {
      makeModelSearchInput: {
        makeCode,
        modelCode,
        chassisMakeCode,
        chassisModelCode,
      },
    },
  });

  const rows = data?.getBidLinesByMakeAndModel || [];

  if (isLoading) return <Spinner data-testid="spinner" size="small" />;

  return (
    <div className="grid-container">
      <div className="grid-row grid-gap">
        <div className="grid-col-5">
          <div className="grid-row">
            <div className="grid-col-5 padding-bottom-105 text-bold">
              Solicitation#
            </div>
            <div className="grid-col-7">
              <div>
                {rows && rows.length > 0 ? (
                  <>
                    {
                      rows[0].solicitationLine?.solicitationProgram
                        ?.solicitation?.solicitationNumber
                    }
                  </>
                ) : (
                  <span>{emDashUnicode}</span>
                )}
              </div>
            </div>
          </div>
          <div className="grid-row">
            <div className="grid-col-5 padding-bottom-105 text-bold">
              Vehicle Program
            </div>
            <div className="grid-col-7">
              <div>
                {rows && rows.length > 0 ? (
                  <>{rows[0].solicitationLine?.solicitationProgram?.program}</>
                ) : (
                  <span>{emDashUnicode}</span>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="grid-col-5">
          <div className="grid-row">
            <div className="grid-col-5 padding-bottom-105 text-bold">
              Comments
            </div>
            <div className="grid-col-7 ">
              <div>{comment || <span>{emDashUnicode}</span>}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid-row padding-top-2">
        <h3 className="text-primary">MAKE AND MODEL CODE</h3>
      </div>
      <div className="grid-row border-bottom border-base-lighter padding-bottom-1">
        <div className="grid-col flex-2 text-bold">Body make</div>
        <div className="grid-col flex-6 ">{makeCode}</div>
      </div>
      <div className="grid-row border-bottom border-base-lighter padding-bottom-1 padding-top-1">
        <div className="grid-col flex-2 text-bold">Body model</div>
        <div className="grid-col flex-6">{modelCode}</div>
      </div>
      <div className="grid-row border-bottom border-base-lighter padding-bottom-1  padding-top-1">
        <div className="grid-col flex-2 text-bold">Chassis make</div>
        <div className="grid-col flex-6 ">
          {chassisMakeCode || emDashUnicode}
        </div>
      </div>
      <div className="grid-row border-bottom border-base-lighter padding-bottom-1 padding-top-1">
        <div className="grid-col flex-2 text-bold">Chassis model</div>
        <div className="grid-col flex-6">
          {chassisModelCode || emDashUnicode}
        </div>
      </div>
    </div>
  );
};

MakeModelApprovalDetailsRow.propTypes = {
  makeCode: PropTypes.number,
  modelCode: PropTypes.string,
  chassisMakeCode: PropTypes.number,
  chassisModelCode: PropTypes.string,
  comment: PropTypes.string,
};

MakeModelApprovalDetailsRow.defaultProps = {
  makeCode: null,
  modelCode: null,
  chassisMakeCode: null,
  chassisModelCode: null,
  comment: '',
};

export default MakeModelApprovalDetailsRow;
