export const getFeatures = () => window?.AFP_CONFIG?.features || {};

export const SUBJECTS = {
  StandardItem: 'StandardItem',
  EquipmentCodes: 'EquipmentCodes',
  CatalogCodes: 'CatalogCodes',
  FVS: 'FederalVehicleStandards',
  Requirements: 'Requirements',
  PSOQuestionnaire: 'PSOQuestionnaire',
  FVSComments: 'FVSComments',
  Solicitation: 'Solicitation',
  All: 'all',
  Bid: 'Bid',
  BidReport: 'BidReport',
  AllBidReports: 'AllBidReports',
  VehicleGroupEngPOCs: 'VehicleGroupEngPOCs',
  VehicleGroupCoPOCs: 'VehicleGroupCoPOCs',
  Contract: 'Contract',
};

export const OPERATIONS = {
  View: 'view',
  Review: 'review',
  EngineerReview: 'engineerReview',
  Create: 'create',
  Update: 'update',
  Delete: 'delete',
  Approve: 'approve',
  Manage: 'manage',
};

export const FVS_STATUS = {
  PeerReview: 'PeerReview',
  PSOCommentOpen: 'PsoCommentOpen',
  PublicCommentOpen: 'PublicCommentOpen',
  AllCommentOpen: 'AllCommentOpen',
  AllCommentClosed: 'AllCommentClosed',
  CommentOpen: 'CommentOpen',
  Published: 'Published',
  Draft: 'Draft',
  Amended: 'Amended',
};

export const FEATURE_NAMES = {
  STANDARD_ITEM: 'STANDARD_ITEM',
  EQUIPMENT_CODE: 'EQUIPMENT_CODE',
  CATALOG_CODE: 'CATALOG_CODE',
  FEDERAL_VEHICLE_STANDARD: 'FEDERAL_VEHICLE_STANDARD',
};

export const LOCALSTORAGE_KEYS = [
  {
    key: 'STANDARD_ITEM_FILTER',
  },
  {
    key: 'EQUIPMENT_CODES_FILTER',
  },
  {
    key: 'CATALOG_CODES_FILTER',
  },
  {
    key: 'SOLICITATION_TABLE_FILTERS',
  },
];

export const enDashUnicode = '\u2013';
export const emDashUnicode = '\u2014';
export const emSpaceUnicode = '\u0020';
export const CUSTOM_ERROR = 'CUSTOM_ERROR';
