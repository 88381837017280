import React from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from '@gsa/afp-shared-ui-utils';
import ContractSearch from './contract-search/contract-search';
import ContractHeader from './contract-header/contract-header';
import ContractLineListing from './contract-line/contract-line-listing';
import ContractLineTemplate from './line-template/contract-line-template';

const Contract = () => {
  return (
    <>
      <Switch>
        <PrivateRoute
          exact
          path="/catalog/contract/"
          title="Contracts"
          component={ContractSearch}
        />

        <PrivateRoute
          exact
          path="/catalog/contract/contract-header/:contractHeaderId"
          title="Contract Header"
          component={ContractHeader}
        />

        <PrivateRoute
          exact
          path="/catalog/contract/contract-header/:contractHeaderId/lines"
          title="Contract Line Listing"
          component={ContractLineListing}
        />

        <PrivateRoute
          exact
          path="/catalog/contract/:contractHeaderId/contract-line-template/:contractLineId"
          title="Contract Line Template"
          component={ContractLineTemplate}
        />
      </Switch>
    </>
  );
};

export default Contract;
