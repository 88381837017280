import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { Spinner } from '@gsa/afp-component-library';
import Programs from '../bids/components/programs';
import useSolicitationDetailsView from './add-edit-solicitation/apis/solicitation-details-view-api';
import {
  CUSTOM_SOL_PERIOD_AUDIENCE_DESCRIPTION,
  PURCHASE_TYPE_MAP,
} from './constants';
import { emDashUnicode } from '../../utilities/constants';

const SolicitationDetailsRow = ({ solicitationId }) => {
  const {
    solicitation,
    solicitationLoading,
    contractYear,
    openPeriod,
    reopenPeriods,
    midcyclePeriods,
    lastSolicitationPeriod,
    programs,
    getSolicitationById,
  } = useSolicitationDetailsView();

  const getSolicitationData = (paramSolId) => {
    getSolicitationById({
      variables: {
        id: parseFloat(paramSolId),
      },
    });
  };

  useEffect(() => {
    if (solicitationId) {
      getSolicitationData(solicitationId);
    }
  }, [solicitationId]);

  return (
    <>
      {solicitationLoading ? (
        <Spinner size="small" />
      ) : (
        <div className="grid-container">
          <Programs programs={programs} />
          <div>
            <div className="text-bold">Description</div>
            <div className="padding-bottom-205 margin-top-2">
              {solicitation.description || <span>{emDashUnicode}</span>}
            </div>
          </div>
          <div className="grid-row grid-gap">
            <div className="tablet:grid-col">
              <div className="grid-row solicitation-separator">
                <div className="tablet:grid-col padding-bottom-105 text-bold">
                  Created by
                </div>
                <div className="tablet:grid-col  text-right">
                  <div className="text-bold">
                    {(solicitation?.createdUser &&
                      `${solicitation?.createdUser.firstName} ${solicitation?.createdUser.lastName}`) ||
                      '-'}
                  </div>
                </div>
              </div>
              <div className="grid-row solicitation-separator">
                <div className="tablet:grid-col padding-bottom-105 text-bold">
                  Last updated by
                </div>
                <div className="tablet:grid-col  text-right">
                  <div className="text-bold">
                    {(solicitation?.updatedUser &&
                      `${solicitation?.updatedUser.firstName} ${solicitation?.updatedUser.lastName}`) ||
                      '-'}
                  </div>
                </div>
              </div>
              <div className="grid-row solicitation-separator">
                <div className="tablet:grid-col padding-bottom-105 text-bold">
                  Latest posted
                </div>
                <div className="tablet:grid-col  text-right">
                  <div className="text-ink">
                    {(lastSolicitationPeriod.startDate &&
                      moment(lastSolicitationPeriod?.startDate).format(
                        'MM-DD-YYYY hh:mm A',
                      )) ||
                      '-'}
                  </div>
                </div>
              </div>
              <div className="grid-row solicitation-separator">
                <div className="tablet:grid-col padding-bottom-105 text-bold">
                  Latest closed
                </div>
                <div className="tablet:grid-col  text-right">
                  <div className="text-ink">
                    {' '}
                    {(lastSolicitationPeriod.endDate &&
                      moment(lastSolicitationPeriod?.endDate).format(
                        'MM-DD-YYYY hh:mm A',
                      )) ||
                      '-'}
                  </div>
                </div>
              </div>
              <div className="grid-row solicitation-separator">
                <div className="tablet:grid-col padding-bottom-105 text-bold">
                  Audience
                </div>
                <div className="tablet:grid-col  text-right">
                  <div className="text-ink">
                    {' '}
                    {(lastSolicitationPeriod.startDate &&
                      CUSTOM_SOL_PERIOD_AUDIENCE_DESCRIPTION[
                        lastSolicitationPeriod?.audience
                      ]) ||
                      '-'}
                  </div>
                </div>
              </div>
              <div className="grid-row solicitation-separator">
                <div className="tablet:grid-col padding-bottom-105 text-bold">
                  Purchase type
                </div>
                <div className="tablet:grid-col  text-right">
                  <div>{PURCHASE_TYPE_MAP[solicitation.purchaseTypeCode]}</div>
                </div>
              </div>
            </div>
            <div className="tablet:grid-col">
              <div className="solicitation-wrapper">
                <div className="padding-bottom-105 text-bold">
                  OPEN, REOPEN, AND MID-CYCLE PERIODS
                </div>

                <div className="grid-row solicitation-separator">
                  <div className="grid-col-5 padding-bottom-105 text-bold">
                    Contract year
                  </div>
                  <div className="grid-col-7  text-right">
                    <div>{contractYear}</div>
                  </div>
                </div>
                {openPeriod.startDate && (
                  <div className="grid-row solicitation-separator">
                    <div className="grid-col-5 padding-bottom-105 text-bold">
                      Open period
                    </div>
                    <div className="grid-col-7 text-right">
                      {`${
                        new Date(openPeriod.startDate)
                          .toISOString()
                          .split('T')[0]
                      } to ${
                        new Date(openPeriod.endDate).toISOString().split('T')[0]
                      }`}
                    </div>
                  </div>
                )}
                {reopenPeriods?.length > 0 &&
                  reopenPeriods.map((reopenPeriod, idx) => {
                    return (
                      <div className="grid-row solicitation-separator">
                        <div className="grid-col-5 padding-bottom-105 text-bold">
                          {`Reopen period ${idx + 1}`}
                        </div>
                        <div className="grid-col-7 text-right">
                          {`${
                            new Date(reopenPeriod.startDate)
                              .toISOString()
                              .split('T')[0]
                          } to ${
                            new Date(reopenPeriod.endDate)
                              .toISOString()
                              .split('T')[0]
                          }`}
                        </div>
                      </div>
                    );
                  })}
                {midcyclePeriods?.length > 0 &&
                  midcyclePeriods.map((midcyclePeriod, idx) => {
                    return (
                      <div className="grid-row solicitation-separator">
                        <div className="grid-col-5 padding-bottom-105 text-bold">
                          {`Mid-cycle period ${idx + 1}`}
                        </div>
                        <div className="grid-col-7 text-right">
                          {`${
                            new Date(midcyclePeriod.startDate)
                              .toISOString()
                              .split('T')[0]
                          } to ${
                            new Date(midcyclePeriod.endDate)
                              .toISOString()
                              .split('T')[0]
                          }`}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

SolicitationDetailsRow.propTypes = {
  solicitationId: PropTypes.string.isRequired,
};

export default SolicitationDetailsRow;
