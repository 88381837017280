/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { AFPTableRowAction, Alert } from '@gsa/afp-component-library';
import { useContractLine } from '../provider/contract-line-provider';
import {
  ReadyIcon,
  TableExpanderCell,
  TextInputField,
  NumberInputField,
  SelectDropdownField,
  TextareaInputField,
} from './ec-components';
import {
  INPUT_TYPE_CODE,
  INPUT_DROPDOWN_OTHER_OPTION,
  INPUT_DROPDOWN_DEFAULT_OPTION,
  CRITERIA_CODE,
  ASSOCIATION_TYPE_CODE,
  EC_OPTION_TYPE,
  isInactiveOptionType,
  EC_QUANTITY_REQUIRED_LIST,
  hasConflicts,
  getConflictDependencies,
} from './ec-helpers';
import { emSpaceUnicode, enDashUnicode } from '../../../../utilities/constants';
import { ConflictsDisplayBlock } from '../optional-equipment/conflicts-components';

const ACTION_CONFLICT = 'Add / edit conflicts';
const actException = {
  icon: 'edit',
  label: 'Add / edit technical exception',
};
const actClarification = {
  icon: 'edit',
  label: 'Add / edit clarification',
};
const actConflict = {
  icon: 'edit',
  label: ACTION_CONFLICT,
};

/** Minimum Requirements tab */
const MinimumRequirementsCell = ({ original }) => {
  const {
    criteriaCode,
    lowerBound,
    upperBound,
    unitCode,
    associationText,
  } = original;
  const getNumberLine = () => {
    let typeText = 'Exact';
    switch (original.inputTypeCode) {
      case INPUT_TYPE_CODE.NUMERICAL:
        if (criteriaCode === CRITERIA_CODE.MIN) typeText = 'Minimum';
        else if (criteriaCode === CRITERIA_CODE.MAX) typeText = 'Maximum';
        return `${typeText} - ${
          lowerBound == null
            ? enDashUnicode
            : lowerBound.toLocaleString('en-US')
        } ${unitCode || ''}`;
      case INPUT_TYPE_CODE.RANGE:
        return `Range - ${
          lowerBound == null
            ? enDashUnicode
            : lowerBound.toLocaleString('en-US')
        }-${
          upperBound == null
            ? enDashUnicode
            : upperBound.toLocaleString('en-US')
        } ${unitCode || ''}`;
      default:
        return '';
    }
  };
  const numberLine = getNumberLine();
  return (
    <div>
      {associationText ? <div>{associationText}</div> : null}
      {numberLine ? <div>{numberLine}</div> : null}
    </div>
  );
};

const VehicleSpecCell = ({ original, onUpdate }) => {
  const { inputTypeCode } = original;
  const options = [INPUT_DROPDOWN_DEFAULT_OPTION];

  const onInputUpdate = useCallback(
    (_original, type, value) => {
      onUpdate(_original, type, value);
    },
    [onUpdate],
  );

  switch (inputTypeCode) {
    case INPUT_TYPE_CODE.DROPDOWN:
      original.preDefinedValue?.value.forEach((value) =>
        options.push({ label: value, value }),
      );
      options.push(INPUT_DROPDOWN_OTHER_OPTION);
      return (
        <SelectDropdownField
          original={original}
          onUpdate={onInputUpdate}
          options={options}
          field="inputValue"
          onUpdateField={inputTypeCode}
          id="pre-defined-value"
          label="Vehicle specifications"
          hideLabel
          required
        />
      );
    case INPUT_TYPE_CODE.COMPLY:
      options.push(
        { label: 'Comply', value: 'Y' },
        { label: 'Not comply', value: 'N' },
      );
      return (
        <SelectDropdownField
          original={original}
          onUpdate={onInputUpdate}
          options={options}
          field="inputValue"
          onUpdateField={inputTypeCode}
          id="select-comply"
          label="Vehicle specifications"
          hideLabel
          required
        />
      );
    case INPUT_TYPE_CODE.NUMERICAL:
    case INPUT_TYPE_CODE.RANGE:
      return (
        <NumberInputField
          original={original}
          onUpdate={onInputUpdate}
          field="inputValue"
          onUpdateField={inputTypeCode}
          id="number-input"
          label="Vehicle specifications"
          hideLabel
          required
        />
      );
    default:
      // FREE TEXT
      return (
        <TextInputField
          original={original}
          onUpdate={onInputUpdate}
          field="inputValue"
          onUpdateField={inputTypeCode}
          id="text-input"
          label="Vehicle specifications"
          hideLabel
          required
        />
      );
  }
};

const ComplyCell = ({ original, onUpdate, isReadOnly = false }) => {
  const options = [
    { label: enDashUnicode, value: '' },
    { label: 'Yes', value: 'Y' },
    { label: 'No', value: 'N' },
  ];
  const disabled =
    original.inputTypeCode && original.inputTypeCode !== INPUT_TYPE_CODE.TEXT;

  const onInputUpdate = useCallback(
    (_original, type, value) => {
      onUpdate(_original, type, value);
    },
    [onUpdate],
  );

  return (
    <SelectDropdownField
      original={original}
      onUpdate={onInputUpdate}
      options={options}
      field="comply"
      id="comply"
      label="Comply"
      required
      hideLabel
      disabled={disabled}
      readonly={isReadOnly}
    />
  );
};

const MinReqActionsCell = ({ row }) => {
  const { getStandardEC, setStandardEC } = useContractLine();
  const original = getStandardEC(row.original);

  const actions =
    original.comply.newValue === 'N'
      ? [actException, actClarification]
      : [actClarification];
  return (
    <AFPTableRowAction
      row={row}
      actions={actions}
      onSelectAction={() => {
        if (!row.original.isExpanded)
          setStandardEC(row.original, { table: { isExpanded: true } });
      }}
    />
  );
};

export const getMinReqColumns = (onUpdate, onActionClick) => [
  {
    // Controlled expander cell
    id: 'expander', // needs an ID w/o Header
    sortable: false,
    headerClassName: 'cell-expander',
    Cell: ({ row }) => {
      const { setStandardEC } = useContractLine();
      return (
        <div style={{ padding: 0 }}>
          <TableExpanderCell
            row={row}
            onClick={(isExpanded) => {
              setStandardEC(row.original, { table: { isExpanded } });
            }}
          />
        </div>
      );
    },
  },
  {
    Header: 'Equipment Code - Title',
    accessor: 'equipment',
    sortable: false,
  },
  {
    Header: 'Minimum requirements',
    sortable: false,
    Cell: ({ row }) => {
      const { getStandardEC } = useContractLine();
      const original = getStandardEC(row.original);
      return <MinimumRequirementsCell original={original} />;
    },
  },
  {
    Header: 'Vehicle specifications',
    sortable: false,
    Cell: ({ row }) => {
      const { getStandardEC } = useContractLine();
      const original = getStandardEC(row.original);
      return <VehicleSpecCell original={original} onUpdate={onUpdate} />;
    },
  },
  {
    Header: 'Comply',
    sortable: false,
    Cell: ({ row }) => {
      const { getStandardEC } = useContractLine();
      const original = getStandardEC(row.original);
      return <ComplyCell original={original} onUpdate={onUpdate} />;
    },
  },
  {
    Header: 'Complete',
    sortable: false,
    Cell: ({ row }) => {
      const { getStandardEC } = useContractLine();
      const original = getStandardEC(row.original);
      return <ReadyIcon isReady={!!original.ready} />;
    },
  },
  {
    Header: 'Actions',
    sortable: false,
    Cell: ({ row }) => (
      <MinReqActionsCell row={row} onActionClick={onActionClick} />
    ),
  },
];

/** Optional Equipment tab */
const OptEqActionsCell = ({ row, onActionClick }) => {
  const { getOptionalEC, setOptionalEC } = useContractLine();
  const original = getOptionalEC(row.original);
  if (isInactiveOptionType(original.inputOptionType)) return null;

  const actions = [actConflict, actException, actClarification];
  const onClick = (action) => {
    if (action === ACTION_CONFLICT) onActionClick(action, row);
    else if (!row.original.isExpanded)
      setOptionalEC(row.original, { table: { isExpanded: true } });
  };
  return (
    <AFPTableRowAction row={row} actions={actions} onSelectAction={onClick} />
  );
};

export const getOptEqColumns = (onUpdate, onActionClick) => [
  {
    // Controlled expander cell
    id: 'expander', // needs an ID w/o Header
    sortable: false,
    headerClassName: 'cell-expander',
    Cell: ({ row }) => {
      const { getOptionalEC, setOptionalEC } = useContractLine();
      const original = getOptionalEC(row.original);
      return (
        <div style={{ padding: 0 }}>
          <TableExpanderCell
            row={row}
            onClick={(isExpanded) => {
              setOptionalEC(row.original, { table: { isExpanded } });
            }}
            isHidden={isInactiveOptionType(original.inputOptionType)}
          />
        </div>
      );
    },
  },
  {
    Header: 'Equipment Code - Title',
    accessor: 'equipment',
    sortable: false,
  },
  {
    Header: 'Option type',
    sortable: false,
    Cell: ({ row }) => {
      const { getOptionalEC, optionalECs, setAlert } = useContractLine();

      const original = getOptionalEC(row.original);
      const options = [
        { label: '-Select-', value: '' },
        { label: 'Standard', value: EC_OPTION_TYPE.standard },
        { label: 'Standalone', value: EC_OPTION_TYPE.standalone },
        { label: 'Not available', value: EC_OPTION_TYPE.na },
      ];
      const onInputUpdate = useCallback(
        (_original, type, value) => {
          onUpdate(_original, type, value);
        },
        [onUpdate],
      );

      const disallowUpdate = (value, showAlert = false) => {
        if (
          !isInactiveOptionType(original.inputOptionType) &&
          isInactiveOptionType({ newValue: value })
        ) {
          const ecHasConflicts = hasConflicts(original);
          const dependencies = getConflictDependencies(original, optionalECs);
          if (ecHasConflicts || dependencies.length > 0) {
            if (showAlert) {
              setAlert({
                type: 'error',
                message: (
                  <div>
                    <div>
                      Cannot change option type of <b>{original.equipment}</b>{' '}
                      due to conflict dependencies.
                    </div>
                    <div className="padding-left-2">
                      {ecHasConflicts && (
                        <div>
                          Please remove the conflicts associated with{' '}
                          <b>{original.equipment}</b>
                        </div>
                      )}
                      {dependencies.length > 0 && (
                        <div>
                          Please remove <b>{original.equipment}</b> from the
                          conflicts associated with the following equipment:
                          {dependencies.map((dep) => (
                            <li className="margin-left-2" key={dep.equipment}>
                              {dep.categoryTitle}: {dep.equipment}
                            </li>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                ),
              });
            }
            return true;
          }
        }
        return false;
      };

      return (
        <SelectDropdownField
          id="option-type"
          field="inputOptionType"
          label="Option type"
          hideLabel
          required
          original={original}
          onUpdate={onInputUpdate}
          options={options}
          disallowUpdate={disallowUpdate}
        />
      );
    },
  },
  {
    Header: 'Max quantity',
    sortable: false,
    Cell: ({ row }) => {
      const { getOptionalEC, isAwardedInFleet } = useContractLine();
      const original = getOptionalEC(row.original);
      const disabled =
        isInactiveOptionType(original.inputOptionType) ||
        !EC_QUANTITY_REQUIRED_LIST.includes(original.quantityRequired);
      const onInputUpdate = useCallback(
        (_original, type, value) => {
          onUpdate(_original, type, value);
        },
        [onUpdate],
      );
      return (
        <NumberInputField
          id="max-quantity"
          field="inputMaxQuantity"
          label="Max quantity"
          hideLabel
          required={isAwardedInFleet}
          disabled={disabled}
          isInt
          original={original}
          onUpdate={onInputUpdate}
          validate={(number) => number > 0}
        />
      );
    },
  },
  {
    Header: 'Model year unit price',
    sortable: false,
    Cell: ({ row }) => {
      const { getOptionalEC } = useContractLine();
      const original = getOptionalEC(row.original);
      const disabled =
        isInactiveOptionType(original.inputOptionType) ||
        original.inputOptionType.newValue === EC_OPTION_TYPE.standard;
      const onInputUpdate = useCallback(
        (_original, type, value) => {
          onUpdate(_original, type, value);
        },
        [onUpdate],
      );
      return (
        <NumberInputField
          id="unit-price"
          field="inputUnitPrice"
          label="Unit price"
          hideLabel
          disabled={disabled}
          required
          prefix="$"
          original={original}
          validate={(number) => {
            if (number < -999999)
              return 'Unit price must be greater than -1,000,000';
            if (number > 999999)
              return 'Unit price must be less than 1,000,000';
            if (!Number.isInteger(number))
              return 'Unit price must be a whole number';
            return true;
          }}
          onUpdate={onInputUpdate}
        />
      );
    },
  },
  {
    Header: 'Additional shipment days',
    sortable: false,
    Cell: ({ row }) => {
      const { getOptionalEC } = useContractLine();
      const original = getOptionalEC(row.original);
      const disabled = isInactiveOptionType(original.inputOptionType);
      const onInputUpdate = useCallback(
        (_original, type, value) => {
          onUpdate(_original, type, value);
        },
        [onUpdate],
      );
      return (
        <NumberInputField
          id="shipment-days"
          field="inputShipmentDays"
          label="Shipment days"
          hideLabel
          disabled={disabled}
          isInt
          original={original}
          onUpdate={onInputUpdate}
          validate={(number) => number >= 0}
        />
      );
    },
  },
  {
    Header: 'Complete',
    sortable: false,
    Cell: ({ row }) => {
      const { getOptionalEC } = useContractLine();
      const original = getOptionalEC(row.original);
      return <ReadyIcon isReady={original.ready} />;
    },
  },
  {
    Header: 'Actions',
    sortable: false,
    Cell: ({ row }) => (
      <OptEqActionsCell row={row} onActionClick={onActionClick} />
    ),
  },
];

// Expanded row for both standard and optional EC tables
export const EcExpandedRow = ({
  ecType,
  row,
  onUpdate,
  onActionClick,
  isReadOnly = false,
}) => {
  const { getStandardEC, getOptionalEC } = useContractLine();
  const original =
    ecType === 'S' ? getStandardEC(row.original) : getOptionalEC(row.original);
  const onExplanationUpdate = useCallback(
    (_original, type, value) => {
      onUpdate(_original, type, value);
    },
    [onUpdate],
  );

  const isOptEq =
    original.associationTypeCode === ASSOCIATION_TYPE_CODE.OPTIONAL;
  const isNonComply = !isOptEq && original.comply.newValue === 'N';

  if (
    isReadOnly &&
    !original.inputException.newValue &&
    !original.inputExplanation.newValue &&
    !(isOptEq && hasConflicts(original))
  ) {
    return null;
  }
  return (
    <div>
      {isNonComply && (
        <div className="margin-top-1 margin-bottom-2">
          <Alert type="info" slim>
            The entered vehicle specification does not meet the GSA minimum
            requirement. Provide explanation for non-compliance.
          </Alert>
        </div>
      )}
      <div className="grid-row margin-y-1">
        <div className="grid-col-6 padding-right-2">
          {isNonComply && (
            <div>
              <div className="text-bold">Technical exception</div>
              {isReadOnly ? (
                <p>{original.inputException.newValue || emSpaceUnicode}</p>
              ) : (
                <TextareaInputField
                  original={original}
                  onUpdate={onUpdate}
                  field="inputException"
                  id="exception-textarea"
                  label="Technical exception"
                  hideLabel
                  help="Explanation for non-compliance is optional"
                  characterLimit={500}
                />
              )}
            </div>
          )}
          {isOptEq && (
            <ConflictsDisplayBlock
              row={row}
              original={original}
              onActionClick={onActionClick}
              isReadOnly={isReadOnly}
            />
          )}
        </div>
        <div className="grid-col-6 padding-left-2">
          {isOptEq && isReadOnly && (
            <div className="margin-bottom-2">
              <div className="text-bold">Technical exception</div>
              <p>{original.inputException.newValue || emSpaceUnicode}</p>
            </div>
          )}
          {isOptEq && !isReadOnly && (
            <div className="margin-bottom-2">
              <div className="text-bold">Technical exception</div>
              <TextareaInputField
                original={original}
                onUpdate={onUpdate}
                field="inputException"
                id="exception-textarea"
                label="Technical exception"
                hideLabel
                help="Provide explanation of any non-compliance for this item"
                characterLimit={500}
              />
            </div>
          )}
          <div className="text-bold">Clarification</div>
          {isReadOnly ? (
            <p>{original.inputExplanation.newValue || emSpaceUnicode}</p>
          ) : (
            <TextareaInputField
              original={original}
              onUpdate={onExplanationUpdate}
              field="inputExplanation"
              id="explanation-textarea"
              label="Clarification"
              hideLabel
              help="Provide any additional information about this item"
              characterLimit={500}
            />
          )}
        </div>
      </div>
    </div>
  );
};
