/* eslint-disable no-restricted-syntax */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@gsa/afp-component-library';
import { useContractLine } from '../provider/contract-line-provider';
import {
  getOptionalEcList,
  ecTitleLookup,
  hasConflicts,
  isInactiveOptionType,
} from '../helpers/ec-helpers';

export const getEditConflictBlock = (
  equipment,
  field,
  getFormFn,
  getSummaryFn,
) => {
  return (
    <div className="grid-row bg-primary-lightest padding-2">
      <div className="grid-col-7">
        <div>
          <div className="text-bold text-primary text-uppercase">{field}</div>
          <div>Equipment code(s)</div>
        </div>
        {getFormFn()}
      </div>

      <div className="grid-col-5">
        <div className="text-bold text-primary text-uppercase">
          {field} summary
        </div>
        <div className="margin-bottom-1">
          {equipment} <span className="text-bold text-uppercase">{field}</span>
        </div>
        {getSummaryFn()}
      </div>
    </div>
  );
};

export const displayCludes = (ecs, ecList) => {
  return (
    <ul className="margin-y-0">
      {ecs.map((ec) => (
        <li key={ec}>{ecTitleLookup(ec, ecList)}</li>
      ))}
    </ul>
  );
};

export const displayRequires = (relations, ecList) => {
  return relations.map((row, i) => (
    <div key={`requires-${row.join('')}`}>
      <ul className="margin-y-0">
        {row.map((ec, j) => (
          <li key={ec}>
            {ecTitleLookup(ec, ecList)}
            {j < row.length - 1 && ', '}
            {j < row.length - 1 && (
              <span className="text-bold font-sans-3xs">AND</span>
            )}
          </li>
        ))}
      </ul>
      {i < relations.length - 1 && (
        <div className="text-bold font-sans-2xs">OR</div>
      )}
    </div>
  ));
};

export const ConflictsDisplayBlock = ({
  row,
  original,
  onActionClick,
  isReadOnly,
}) => {
  const { optionalECs } = useContractLine();
  const ecList = getOptionalEcList(optionalECs);
  const noConflict = !hasConflicts(original);

  const showConflict = (header, field, displayFn) => {
    if (!original[field].newValue.length) return null;
    return (
      <div className="grid-row margin-bottom-2">
        <div className="grid-col-2 text-bold text-right padding-right-1">
          {header}
        </div>
        <div className="grid-col-10">
          {displayFn(original[field].newValue, ecList)}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="grid-row">
        <div className="grid-col-2 text-bold text-primary padding-top-05">
          CONFLICTS
        </div>
        {!isReadOnly && (
          <div className="grid-col-10 padding-left-3">
            <Button
              variant="unstyled"
              label={`${noConflict ? 'Add' : 'Edit'} conflict`}
              leftIcon={{ name: noConflict ? 'add' : 'edit' }}
              onClick={() => onActionClick('conflict', row)}
            />
          </div>
        )}
      </div>
      <div className="margin-top-1 margin-bottom-2">
        {showConflict('Includes', 'includes', displayCludes)}
        {showConflict('Excludes', 'excludes', displayCludes)}
        {showConflict('Requires', 'requires', displayRequires)}
      </div>
    </div>
  );
};

ConflictsDisplayBlock.propTypes = {
  row: PropTypes.shape(PropTypes.objectOf({})),
  original: PropTypes.shape(PropTypes.objectOf({})),
  onActionClick: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

ConflictsDisplayBlock.defaultProps = {
  row: {},
  original: {},
  isReadOnly: false,
  onActionClick: () => {},
};

/**
 * Helper functions to validate conflicts dependencies
 */
const equalSets = (setA, setB) => {
  if (setA.length !== setB.length) return false;
  for (const a of setA) if (!setB.find((b) => b.ec === a.ec)) return false;
  return true;
};
const findContradictEc = (ecItem, bucket) => {
  for (const ec of ecItem.excludes)
    if (bucket.find((i) => i.ec === ec)) return ec;
  return null;
};
const pushBucket = (destBuckets, srcBuckets) => {
  for (const bucket of srcBuckets)
    if (!destBuckets.some((dest) => equalSets(dest, bucket)))
      destBuckets.push(bucket);
};

const getConflictEcList = (optionalECs) => {
  const ecList = [];
  optionalECs.forEach((cat) => {
    ecList.push(
      ...cat.data
        .filter(({ inputOptionType }) => !isInactiveOptionType(inputOptionType))
        .map(({ id, equipmentCode: ec, includes, excludes, requires }) => ({
          id,
          ec,
          includes: includes.newValue,
          excludes: excludes.newValue,
          requires: requires.newValue,
        })),
    );
  });
  return ecList;
};

const processMust = (EC, parent, relation, buckets, conflictECs) => {
  const newBuckets = [];
  for (const bucket of buckets) {
    const me = conflictECs.find(({ ec }) => EC === ec);
    if (!me || bucket.find(({ ec }) => EC === ec)) newBuckets.push(bucket);
    else {
      const copyBucket = [...bucket];
      copyBucket.push({ ...me, parent, relation });
      if (!me.includes.length && !me.requires.length)
        newBuckets.push(copyBucket);
      else {
        const myNewBuckets = [];
        const myrequires = me.requires.filter((req) => req.length > 0);
        const requires = myrequires.length ? myrequires : [[]];
        for (const R of requires) {
          let myBuckets = [[...copyBucket]];
          for (const ec of me.includes) {
            const newEcBuckets = [];
            for (const myBucket of myBuckets) {
              const ecBuckets = processMust(
                ec,
                EC,
                'includes',
                [myBucket],
                conflictECs,
              );
              newEcBuckets.push(...ecBuckets);
            }
            myBuckets = newEcBuckets;
          }
          for (const ec of R) {
            const newEcBuckets = [];
            for (const myBucket of myBuckets) {
              const ecBuckets = processMust(
                ec,
                EC,
                'requires',
                [myBucket],
                conflictECs,
              );
              newEcBuckets.push(...ecBuckets);
            }
            myBuckets = newEcBuckets;
          }
          pushBucket(myNewBuckets, myBuckets);
        }
        pushBucket(newBuckets, myNewBuckets);
      }
    }
  }
  return newBuckets;
};

const traceConflict = (ec, list) => {
  const ecItem = list.find((i) => i.ec === ec);
  if (ecItem.parent) {
    const trace = traceConflict(ecItem.parent, list);
    return `${trace} ${trace.search(' ') < 0 ? '' : 'which '}${
      ecItem.relation
    } ${ec}`;
  }
  return ec;
};

export const validateConflicts = (EC, field, value, optionalECs) => {
  const conflictECs = getConflictEcList(optionalECs);
  const ecIndex = conflictECs.findIndex(({ ec }) => ec === EC);
  conflictECs.splice(ecIndex, 1, {
    ...conflictECs[ecIndex],
    [field]: value,
  });

  for (let i = 0; i < conflictECs.length; i += 1) {
    const musts = processMust(
      conflictECs[i].ec,
      undefined,
      undefined,
      [[]],
      conflictECs,
    );
    for (const mustSet of musts)
      for (const ecItem of mustSet) {
        const contradictEc = findContradictEc(ecItem, mustSet);
        const excTrace = traceConflict(ecItem.ec, mustSet);
        if (contradictEc)
          return {
            isValid: false,
            rootEc: conflictECs[i].ec,
            contradictEc,
            includeTrace: traceConflict(contradictEc, mustSet),
            excludeTrace: `${excTrace} ${
              excTrace.search(' ') < 0 ? '' : 'which '
            }excludes ${contradictEc}`,
          };
      }
  }
  return { isValid: true };
};

export const showConflictTrace = (trace, EC, field, value) => {
  const ecs =
    field === 'requires'
      ? value.reduce((sum, val) => {
          sum.push(...val);
          return sum;
        }, [])
      : [...value];
  for (let i = 0; i < ecs.length; i += 1) {
    const segs = [`${EC} ${field} ${ecs[i]}`, `${EC} which ${field} ${ecs[i]}`];
    for (let j = 0; j < 2; j += 1) {
      if (trace.search(segs[j]) >= 0) {
        const a = trace.split(segs[j]);
        return (
          <span>
            {a[0]} <span className="text-bold bg-yellow">{segs[j]}</span> {a[1]}
          </span>
        );
      }
    }
  }
  return trace;
};

export const exportsForTesting = {
  equalSets,
  pushBucket,
};
