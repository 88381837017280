import React, { useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AFPTable, EmptyState, Button } from '@gsa/afp-component-library';
import { useContractLine } from '../../provider/contract-line-provider';

const VendorAssociatedLocationTable = ({ isReadOnly }) => {
  const {
    contractLine,
    setAlert,
    detailTabData,
    setDetailTabData,
    vendorLocations,
  } = useContractLine();

  // Maintain a local state for associated locations
  const [associatedLocations, setAssociatedLocations] = useState([]);

  // Synchronize the associated locations from detailTabData and contractLine
  useEffect(() => {
    setAssociatedLocations(
      detailTabData?.associatedLocations ||
        contractLine?.associatedLocations ||
        [],
    );
  }, [detailTabData, contractLine]);

  const handleDelete = useCallback(
    ({ original }) => {
      setAlert(null);
      setAssociatedLocations((prevLocations) => {
        const clearedLocs = prevLocations.filter((a) => a.id !== original.id);
        setDetailTabData((prevData) => ({
          ...prevData,
          associatedLocations: clearedLocs,
        }));
        return clearedLocs;
      });
    },
    [setAlert, setDetailTabData],
  );

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Final assembly point',
        accessor: 'vendorAssemblyPointId',
        sortable: false,
        Cell: ({ value }) => {
          const assembly = vendorLocations?.find((b) => +b.id === value);
          return `${assembly?.country?.countryName} (${assembly?.country?.isoCountryCode2}) 
              ${assembly?.address1} ${assembly?.city} ${assembly?.state?.stateCode},
              ${assembly?.postalCode} 
            `;
        },
      },
      {
        Header: 'Inspection point',
        accessor: 'vendorInspectionPointId',
        sortable: false,
        Cell: ({ value }) => {
          const inspection = vendorLocations?.find((b) => +b.id === value);
          return `${inspection?.country?.countryName} (${inspection?.country?.isoCountryCode2}) 
              ${inspection?.address1} ${inspection?.city} ${inspection?.state?.stateCode},
              ${inspection?.postalCode} 
            `;
        },
      },
      {
        Header: 'Percentage of domestic content',
        accessor: 'percentageOfDomContent',
        sortable: false,
      },
    ];
    if (!isReadOnly) {
      columnList.push({
        Header: 'Actions',
        sortable: false,
        Cell: (props) => (
          <Button
            variant="unstyled"
            // eslint-disable-next-line
            onClick={() => handleDelete(props.row)}
            label="Delete"
            style={{ width: 100 }}
            leftIcon={{
              name: 'Cancel',
              className: 'text-base-dark',
            }}
          />
        ),
      });
    }
    return columnList;
  }, [vendorLocations, isReadOnly, handleDelete]);

  return (
    <div
      className={`grid-col flex-2 ${
        isReadOnly ? '' : 'margin-top-4 margin-bottom-4'
      }`}
    >
      {vendorLocations?.length > 0 && (
        <AFPTable
          testId="assembly_inspection_point_table"
          columns={columns}
          data={associatedLocations}
        />
      )}
      {!associatedLocations?.length && (
        <div className="margin-top-2">
          <div className="table-empty-state">
            <EmptyState bottomText="No Assembly point/Inspection point found." />
          </div>
        </div>
      )}
    </div>
  );
};

VendorAssociatedLocationTable.propTypes = {
  isReadOnly: PropTypes.bool,
};

VendorAssociatedLocationTable.defaultProps = {
  isReadOnly: false,
};

export default VendorAssociatedLocationTable;
