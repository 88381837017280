/* eslint-disable react/prop-types */
/*  eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import {
  SelectDropdown,
  Button,
  RequiredFieldIndicator,
  StatusBadge,
} from '@gsa/afp-component-library';
import '../../../fvs/requirements/components/make-model/make-model-table.scss';
import MakeTypeAhead from '../../../fvs/requirements/components/make-model/make-type-ahead';
import ModelTypeAhead from '../../../fvs/requirements/components/make-model/model-type-ahead';
import { useBidMakeAndModelContext } from './make-and-model-provider';
import { getMakeModelStatusBadgeConfig } from '../../helpers';
import { emDashUnicode } from '../../../../utilities/constants';
import CanIChangeBidData from '../../../bids/components/protect-bid-data-crud';

const MakeAndModelHeader = ({ title, required }) => {
  return (
    <div className="titleHeader" aria-label={title} tabIndex="0">
      {title}
      {required && (
        <span className="margin-left-05">
          <RequiredFieldIndicator />
        </span>
      )}
    </div>
  );
};

const MakeAndModelSearchPanel = ({
  hasChassis,
  onAddMakeModel,
  action,
  disabled = false,
}) => {
  const {
    makeCode,
    makeName,
    modelCode,
    modelName,
    status,
    chassisMakeCode,
    chassisMakeName,
    chassisModelCode,
    chassisModelName,
    modelYear,
    chassisMakeCustomKeyword,
    chassisModelCustomKeyword,
    makeCustomKeyword,
    modelCustomKeyword,
    setAddMakeModelContext,
    isSubmitted,
    isClearedTypeAhead,
  } = useBidMakeAndModelContext();

  const handleModelChanged = (val) => {
    setAddMakeModelContext('SET_MODEL', val);
  };
  const handleMakeChanged = (val) => {
    handleModelChanged();
    setAddMakeModelContext('SET_MAKE', val);
  };
  const handleChassisModelChanged = (val) => {
    setAddMakeModelContext('SET_CHASSIS_MODEL', val);
  };
  const handleChassisMakeChanged = (val) => {
    handleChassisModelChanged();
    setAddMakeModelContext('SET_CHASSIS_MAKE', val);
  };
  const handleModelYearChanged = (val) => {
    setAddMakeModelContext('SET_MODEL_YEAR', val);
  };

  const getModelYearRange = (previousYears, nextYears) => {
    const currentYear = new Date().getFullYear();
    const years = [{ value: '', label: '-Select-' }];
    for (
      let i = currentYear - previousYears;
      i <= currentYear + nextYears;
      i += 1
    ) {
      years.push({ value: i, label: i.toString() });
    }
    return years;
  };

  const badge = getMakeModelStatusBadgeConfig(status);

  return (
    <>
      <div className="grid-row grid-gap bg-primary-lighter padding-top-2">
        <div className="grid-col flex-1 margin-bottom-3">
          <MakeAndModelHeader title="Body Make" required />
          <MakeTypeAhead
            dataTestId="make"
            required
            isClearedTypeAhead={isClearedTypeAhead}
            isSubmitted={isSubmitted}
            onChange={handleMakeChanged}
            makeCode={makeCode}
            makeName={makeName}
            makeCustomKeyword={makeCustomKeyword}
            setFvsPreBidContextData={setAddMakeModelContext}
            customKeywordKey="SET_MAKE_CUSTOM_KEYWORD"
            placeholder="Search makes"
          />
        </div>
        <div className="grid-col flex-1">
          <MakeAndModelHeader title="Body Model" required />
          <ModelTypeAhead
            dataTestId="model"
            isClearedTypeAhead={isClearedTypeAhead}
            isSubmitted={isSubmitted}
            onChange={handleModelChanged}
            modelCode={modelCode}
            makeCode={makeCode}
            modelName={modelName}
            modelCustomKeyword={modelCustomKeyword}
            setFvsPreBidContextData={setAddMakeModelContext}
            customKeywordKey="SET_MODEL_CUSTOM_KEYWORD"
            placeholder="Search models"
          />
        </div>
        {hasChassis && (
          <div className="grid-col flex-1">
            <>
              <MakeAndModelHeader title="Chassis Make" required />
              <MakeTypeAhead
                dataTestId="chassisMake"
                isClearedTypeAhead={isClearedTypeAhead}
                isSubmitted={isSubmitted}
                onChange={handleChassisMakeChanged}
                makeCode={chassisMakeCode}
                makeName={chassisMakeName}
                makeCustomKeyword={chassisMakeCustomKeyword}
                setFvsPreBidContextData={setAddMakeModelContext}
                customKeywordKey="SET_CHASSIS_MAKE_CUSTOM_KEYWORD"
                placeholder="Search chassis makes"
              />
            </>
          </div>
        )}
        {hasChassis && (
          <div className="grid-col flex-1">
            <>
              <MakeAndModelHeader title="Chassis Model" required />
              <ModelTypeAhead
                dataTestId="chassisModel"
                isClearedTypeAhead={isClearedTypeAhead}
                isSubmitted={isSubmitted}
                onChange={handleChassisModelChanged}
                makeCode={chassisMakeCode}
                modelName={chassisModelName}
                modelCode={chassisModelCode}
                modelCustomKeyword={chassisModelCustomKeyword}
                setFvsPreBidContextData={setAddMakeModelContext}
                customKeywordKey="SET_CHASSIS_MODEL_CUSTOM_KEYWORD"
                placeholder="Search chassis models"
              />
            </>
          </div>
        )}
        <div className="grid-col flex-1">
          <MakeAndModelHeader title="Model year" required />
          <SelectDropdown
            name="model_year"
            onChange={({ target: { value } }) => handleModelYearChanged(value)}
            value={modelYear}
            options={getModelYearRange(1, 3)}
            className="margin-top-4"
          />
        </div>
        {action === 'add' && (
          <div className="grid-col flex-1">
            <CanIChangeBidData>
              <Button
                leftIcon={{
                  name: 'add',
                }}
                type="button"
                variant="outline"
                onClick={onAddMakeModel}
                label="Add"
                aria-label="Add"
                className="margin-top-7"
                disabled={disabled}
              />
            </CanIChangeBidData>
          </div>
        )}
        {action === 'update' && (
          <div className="grid-col flex-1">
            <MakeAndModelHeader title="Status" />
            <div className="margin-top-4">
              {badge ? (
                <StatusBadge variant={badge.variant}>{badge.label}</StatusBadge>
              ) : (
                emDashUnicode
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MakeAndModelSearchPanel;
