import React from 'react';

import {
  ButtonDropdown,
  ButtonDropdownItem,
  FlexView,
} from '@gsa/afp-component-library';

const ContractLineActionsPannel = () => {
  const actionsPanelLinks = (handler) => [
    {
      title: 'View contract history',
      onClickHandler: handler?.viewContractHistory,
      customIcon: true,
      icon: 'clock',
    },
  ];

  const actionHandlers = {
    viewContractHistory: () => {},
  };

  return (
    <FlexView hAlignContent="right">
      <ButtonDropdown
        className="margin-right-0 margin-bottom-1"
        label="Actions"
        variant="outline"
        iconSize={3}
        side="right"
      >
        {actionsPanelLinks(actionHandlers).map((link) => (
          <React.Fragment key={link.title}>
            {link ? (
              <ButtonDropdownItem
                className="text-primary hover:bg-blue-5"
                label={link.title}
                iconName={link.icon}
                iconType={link.customIcon && 'custom'}
                iconFill="#005ea2"
                onClick={() => link.onClickHandler()}
              />
            ) : null}
          </React.Fragment>
        ))}
      </ButtonDropdown>
    </FlexView>
  );
};

export default ContractLineActionsPannel;
