/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Typeahead } from '@gsa/afp-component-library';
import { GET_MODEL_BY_PARTIAL_MATCH } from '../../../fvs.gql';

let userTyped = false;
let globalMakeCode = '';

const ModelTypeAhead = ({
  onChange,
  makeCode,
  modelName,
  modelCustomKeyword,
  setFvsPreBidContextData,
  customKeywordKey,
  isSubmitted,
  placeholder,
  dataTestId,
}) => {
  const [option, setOption] = useState({ values: [], raw: [] });
  const [getModelsByPartialModelName] = useLazyQuery(
    GET_MODEL_BY_PARTIAL_MATCH,

    {
      onCompleted: (data) => {
        let result = { values: [], raw: [] };
        if (data?.getModelsByPartialMatch?.length) {
          result = {
            values: data.getModelsByPartialMatch?.map(
              (m) => `${m.modelName} - ${m.modelCode}`,
            ),
            raw: data.getModelsByPartialMatch,
          };
        }
        setOption(() => {
          if (result?.values?.length > 0) {
            return result;
          }
          return { values: ['No results found.'], raw: ['No results found.'] };
        });
      },
    },
  );

  useEffect(() => {
    globalMakeCode = makeCode;
  }, [makeCode]);

  const forceUpdateValue =
    modelCustomKeyword != null && !modelName
      ? { value: modelCustomKeyword }
      : false;

  const handleFetch = useCallback(
    (_, search) => {
      const keyword = search.trim();
      if (userTyped) {
        let variables;
        if (globalMakeCode) {
          variables = {
            modelName: keyword,
            makeCode: Number.parseInt(globalMakeCode, 10),
            dataSource: 'AFP',
          };
        } else {
          variables = {
            modelName: keyword,
            makeCode: -1,
            dataSource: 'AFP',
          };
        }
        getModelsByPartialModelName({
          variables,
        });
        userTyped = false;
      }
    },
    [makeCode],
  );

  const generateCustomOption = (opt) => {
    const item = option?.raw.find((o) => o.modelCode === opt?.split(' - ')[1]);
    return (
      <>
        {item ? (
          <div className="display-flex flex-column">
            <span className="text-ink">Model: {item?.modelName}</span>
            <span className="text-ink">Code: {item?.modelCode}</span>
          </div>
        ) : (
          opt
        )}
      </>
    );
  };

  return (
    <Typeahead
      id={dataTestId}
      role="textbox"
      name="makeModel"
      filterValue={modelName}
      typeaheadValues={option?.values}
      onFilterKeyDown={(e) => {
        userTyped = true;
        setFvsPreBidContextData(customKeywordKey, e.target.value);
      }}
      onOptionEnter={(selected) => {
        const selectedData = option?.raw.find(
          (o) => o.modelCode === selected?.split(' - ')[1],
        );
        if (selectedData) {
          onChange({ ...selectedData });
        }
        setFvsPreBidContextData(customKeywordKey, '');
        setFvsPreBidContextData('SET_IS_SUBMITTED', false);
      }}
      fetchTypeaheadValues={handleFetch}
      debounceDelay={500}
      inputCharNum={0}
      forceUpdate={forceUpdateValue}
      reset={isSubmitted}
      placeholder={placeholder}
      onClear={(selected) => {
        onChange({ selected, undefined });
      }}
      generateCustomOption={generateCustomOption}
      disablePaste
      promptText="Type to search for models"
    />
  );
};

ModelTypeAhead.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default ModelTypeAhead;
