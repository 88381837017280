import React, { useState } from 'react';
import { PageTitle } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import Breadcrumbs from '../../../widgets/breadcrumbs';
import ContractSearchSection from './contract-search-section';
import ContractSearchTable from './contract-search-table';
import { SEARCH_OPTIONS, getKeyLabel } from './contract-search-helpers';
import { GET_CONTRACT_HEADERS_BY_CRITERIA } from './contract-search-gql';

const ContractSearch = () => {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [contracts, setContracts] = useState([]);
  const [contractCount, setContractCount] = useState(0);

  const [searchKey, setSearchKey] = useState(SEARCH_OPTIONS[0].value);
  const [searchValue, setSearchValue] = useState('');
  const [searchResult, setSearchResult] = useState(null);

  const [getContractHeadersByCriteria, { loading }] = useLazyQuery(
    GET_CONTRACT_HEADERS_BY_CRITERIA,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        if (!res?.getContractHeadersByCriteria?.count) {
          setContractCount(0);
          setSearchResult(
            'There are no contracts matching the search criteria.',
          );
          return;
        }

        setContractCount(res.getContractHeadersByCriteria.count);
        setSearchResult(() => {
          const key = getKeyLabel(searchKey, true);
          return `Search results for ${key} "${searchValue}"`;
        });
        setContracts(res.getContractHeadersByCriteria.rows);
      },
    },
  );

  const onSearch = () => {
    if (!searchValue) {
      return;
    }

    setOffset(0); // reset to page 1
    getContractHeadersByCriteria({
      variables: {
        criteria: { [searchKey]: searchValue },
        limit,
        offset,
      },
    });
  };

  const onPageChange = (currentPage, itemsPerPage) => {
    let newOffset;
    if (itemsPerPage === limit) {
      newOffset = (currentPage - 1) * itemsPerPage;
    } else {
      newOffset = 0; // reset to page 1
      setLimit(itemsPerPage);
    }
    setOffset(newOffset);
    getContractHeadersByCriteria({
      variables: {
        criteria: { [searchKey]: searchValue },
        limit: itemsPerPage,
        offset: newOffset,
      },
    });
  };

  return (
    <>
      <div className="margin-bottom-8">
        <Breadcrumbs
          current="Contracts"
          path={[
            {
              location: `${window.AFP_CONFIG.appURLs?.home}/home`,
              label: 'Home',
            },
          ]}
        />
        <div className="grid-row margin-bottom-2">
          <div className="grid-col-10">
            <PageTitle
              title="Contracts"
              aria-label="Contracts"
              className="margin-bottom-0"
            />
            <div>
              Search contracts by contract number, solicitation uPIID,
              solicitation name and Vendor name/UEI.
            </div>
          </div>
        </div>
        <ContractSearchSection
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onSearch={onSearch}
        />
        {searchResult && (
          <div className="text-bold margin-top-05">{searchResult}</div>
        )}
        <ContractSearchTable
          loading={loading}
          data={contracts}
          count={contractCount}
          offset={offset}
          limit={limit}
          onPageChange={onPageChange}
          onSort={() => {}}
        />
      </div>
    </>
  );
};

export default ContractSearch;
