const matchLines = (line1, line2) => {
  const chassis1Make =
    line1.chassisMakeCode === 1 ? null : line1.chassisMakeCode;
  const chassis2Make =
    line2.chassisMakeCode === 1 ? null : line2.chassisMakeCode;
  const chassis1Model =
    line1.chassisModelCode === '-1' ? null : line1.chassisModelCode;
  const chassis2Model =
    line2.chassisModelCode === '-1' ? null : line2.chassisModelCode;
  return (
    line1.standardItemId === line2.standardItemId &&
    line1.makeCode === line2.makeCode &&
    line1.modelCode === line2.modelCode &&
    line1.modelYear === line2.modelYear &&
    chassis1Make === chassis2Make &&
    chassis1Model === chassis2Model
  );
};

const getLineLabel = (line, contractYear) => {
  const sin = line.standardItem?.standardItemNumber || '';
  const make = line.afpMake?.makeName || '';
  const model = line.afpModel?.modelName || '';
  const modelYear = line.modelYear || '';
  const makeModel = [make, model, modelYear].filter((a) => a).join('/');
  const chassisMake = line.chassisMake?.makeName || '';
  const chassisModel = line.chassisModel?.modelName || '';
  const chassis =
    chassisMake && chassisModel
      ? `(chassis ${chassisMake}/${chassisModel})`
      : '';
  return `${contractYear} ${sin} ${makeModel} ${chassis}`.trim();
};

export const processAwardedBidLines = (
  bidLines,
  contractLines,
  contractYear,
) => {
  const awardedLines = bidLines
    .map((line) => {
      const cLine = contractLines.find((cl) => matchLines(cl, line));
      return {
        bidLineId: line.id,
        contractLineId: cLine?.id || '',
        label: getLineLabel(line, contractYear),
        imported: cLine?.bidLineId === +line.id,
      };
    })
    .sort((a, b) => (a.label > b.label ? 1 : -1));
  const mapFn = (item) => ({
    id: `${item.bidLineId}|${item.contractLineId}`,
    label: item.label,
  });
  return {
    newLines: awardedLines.filter((line) => !line.contractLineId).map(mapFn),
    existingLines: awardedLines
      .filter((line) => !line.imported && line.contractLineId)
      .map(mapFn),
  };
};

export const exportForTesting = {
  matchLines,
  getLineLabel,
};
