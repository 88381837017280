import React, { useState, useMemo, useCallback } from 'react';
import {
  Button,
  AFPTable,
  EmptyState,
  FlexView,
} from '@gsa/afp-component-library';
import { getDocumentColumns, DELETE_ACTION } from './table-defs';
import { ATTACH_TYPE } from '../../../bid-dashboard/bid-line-details/provider/helpers';
import AddDocModal from './add-doc-modal';
import Notification from './notification';
import DeleteDocModal from './delete-doc-modal';
import ImagesTable from './images-table';
import { useContractLine } from '../provider/contract-line-provider';

const DocumentationTab = () => {
  const [isAddDocModal, setIsAddDocModal] = useState(false);
  const [isAddImageModal, setIsAddImageModal] = useState(false);
  const [docSuccessMsg, setDocSuccessMsg] = useState();
  const [imageSuccessMsg, setImageSuccessMsg] = useState();
  const [docToDelete, setDocToDelete] = useState(null);

  const {
    documents,
    photos,
    contractLineId,
    setAlert,
    loadContractLineAttachments,
  } = useContractLine();

  const onActionClick = useCallback((type, action, { original }) => {
    if (action === DELETE_ACTION) {
      setDocToDelete(original);
    }
  }, []);

  const onDocumentActionClick = useCallback(
    (action, row) => onActionClick(ATTACH_TYPE.doc, action, row),
    [],
  );

  const documentColumns = useMemo(
    () => getDocumentColumns(onDocumentActionClick),
    [onDocumentActionClick],
  );

  const onAddClick = useCallback(
    (docType) => {
      setImageSuccessMsg(null);
      setDocSuccessMsg(null);
      setAlert(null);
      if (documents.length + photos.length >= 10) {
        setAlert({
          type: 'error',
          message:
            'You have exceeded the allowed maximum of 10 pictures/documents per contract line.',
        });
        return;
      }
      if (docType === ATTACH_TYPE.doc) {
        setIsAddDocModal(true);
      } else {
        setIsAddImageModal(true);
      }
    },
    [documents, photos],
  );

  return (
    <FlexView column className="margin-top-6">
      <div>
        <span className="title-m-bold margin-bottom-4">Documentation</span>
      </div>
      <h4 className="text-primary">DOCUMENTS</h4>
      {docSuccessMsg && <Notification type="success" content={docSuccessMsg} />}
      <div className="display-flex flex-row flex-justify margin-top-4">
        <div style={{ maxWidth: '40rem' }}>
          Upload a combined maximum of 10 pictures/documents related to the
          contract line.
        </div>
        <div className="margin-top-2">
          <Button
            variant="outline"
            label="Add documents"
            leftIcon={{ name: 'add' }}
            onClick={() => onAddClick(ATTACH_TYPE.doc)}
          />
          {isAddDocModal && (
            <AddDocModal
              contractLineId={contractLineId}
              onClose={() => setIsAddDocModal(false)}
              onSuccess={(newDoc) => {
                loadContractLineAttachments();
                setDocSuccessMsg(
                  `You have successfully uploaded ${newDoc.name}`,
                );
                setIsAddDocModal(false);
              }}
              docType="D"
            />
          )}
          {docToDelete && (
            <DeleteDocModal
              doc={docToDelete}
              onCancel={() => setDocToDelete(null)}
              onSuccess={() => {
                if (docToDelete.documentType === 'D') {
                  setDocSuccessMsg(
                    `${docToDelete.name} has been removed from the contract line.`,
                  );
                } else {
                  setImageSuccessMsg(
                    `${docToDelete.name} has been removed from the contract line.`,
                  );
                }

                loadContractLineAttachments();
                setDocToDelete(null);
              }}
            />
          )}
        </div>
      </div>
      <AFPTable
        fullWidth
        columns={documentColumns}
        data={documents}
        defaultSort={[['name', 'asc']]}
      />
      {!documents.length && (
        <div className="table-empty-state">
          <EmptyState bottomText="No documents available. Please use the add documents button to upload documents." />
        </div>
      )}

      <h4 className="text-primary">PHOTOS</h4>
      {imageSuccessMsg && (
        <Notification type="success" content={imageSuccessMsg} />
      )}
      <div className="display-flex flex-row flex-justify margin-top-4">
        <div style={{ maxWidth: '40rem' }}>
          Upload a combined maximum of 10 pictures/documents related to the
          contract line. A representative image must be selected to open the
          contract line.
        </div>
        <div className="margin-top-2">
          <Button
            variant="outline"
            label="Add photos"
            leftIcon={{ name: 'add' }}
            onClick={() => onAddClick(ATTACH_TYPE.photo)}
          />
          {isAddImageModal && (
            <AddDocModal
              contractLineId={contractLineId}
              onClose={() => setIsAddImageModal(false)}
              onSuccess={(newPhoto) => {
                loadContractLineAttachments();
                setImageSuccessMsg(
                  `You have successfully uploaded ${newPhoto.name}`,
                );
                setIsAddImageModal(false);
              }}
              docType="I"
            />
          )}
        </div>
      </div>
      <ImagesTable setDocToDelete={setDocToDelete} />
    </FlexView>
  );
};

DocumentationTab.propTypes = {};

export default DocumentationTab;
