import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import {
  Modal,
  Spinner,
  Button,
  connectModal,
} from '@gsa/afp-component-library';
import { APPROVE_REJECT_MODEL } from '../queries.gql';
import { makeModelStatusMessageAtom } from '../atoms/make-model-atoms';
import CommentSection from './make-model-modal-comment-section';

const LoadingSpinner = () => (
  <div
    role="none"
    className="afp-modal-overlay"
    data-testid="approve-reject-modal-loading-spinner"
  >
    <Spinner
      style={{
        position: 'absolute',
        top: '50%',
        transform: 'translate(0, -50%)',
      }}
    />
  </div>
);

const ModelApproveRejectModal = ({
  data,
  action,
  isOpen,
  onClose,
  refetchData,
}) => {
  const { make, modelCode, modelName } = data;
  const [, setToaster] = useRecoilState(makeModelStatusMessageAtom);
  const [commentBody, setCommentBody] = useState('');

  const MakeModelApprovalStatus = {
    Pending: 0,
    Approved: 1,
    Rejected: 2,
    GSAReview: 3,
    Archived: 4,
    PendingReview: 5,
  };

  const [approveRejectModel, { loading }] = useMutation(APPROVE_REJECT_MODEL, {
    variables: {
      modelCode,
      fleetStatus:
        action === 'Approve'
          ? MakeModelApprovalStatus.Approved
          : MakeModelApprovalStatus.Rejected,
      comment: commentBody || '',
    },
    onCompleted: () => {
      setToaster({
        type: 'success',
        message: (
          <>
            You have successfully{' '}
            {action === 'Approve' ? 'approved' : 'rejected'} {make.makeName} and{' '}
            {modelName}
          </>
        ),
      });
      refetchData();
      onClose();
    },
    onError: () => onClose(),
  });

  return (
    <Modal
      id="remove-standard-item-modal"
      variant="medium"
      onClose={onClose}
      isOpen={isOpen}
      actions={
        <div className="flex-align-start flex-justify-start">
          <Button
            data-testid="approve-reject-modal-cancel-btn"
            variant="unstyled"
            onClick={onClose}
            className="padding-right-1"
            label="Cancel"
          />
          <Button
            variant={action === 'Approve' ? 'primary' : 'secondary'}
            onClick={approveRejectModel}
            label={action === 'Approve' ? 'Approve' : 'Reject'}
          />
        </div>
      }
    >
      <>
        {loading && <LoadingSpinner />}
        <div>
          <div className="margin-top-10 margin-bottom-8">
            <span>
              <strong>
                Are you sure you would like to{' '}
                {action === 'Approve' ? 'approve' : 'reject'} the make/model?
              </strong>
            </span>
            <br />
            {make.makeName} / {modelName}
          </div>
          {action === 'Reject' && (
            <CommentSection
              commentBody={commentBody}
              onCommentChange={setCommentBody}
            />
          )}
        </div>
      </>
    </Modal>
  );
};

ModelApproveRejectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.shape(
    PropTypes.objectOf({
      make: PropTypes.object.isRequired,
      makeName: PropTypes.string.isRequired,
      modelCode: PropTypes.string.isRequired,
      modelName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  action: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  refetchData: PropTypes.func.isRequired,
};

const ConnectedModelApprovalModal = connectModal(ModelApproveRejectModal);

export default ConnectedModelApprovalModal;
