import React, { useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  TextInput,
  RequiredFieldIndicator,
  Icon,
  SelectDropdown,
  DatePicker,
} from '@gsa/afp-component-library';
import { emSpaceUnicode } from '../../../../utilities/constants';
import { useContractLine } from '../provider/contract-line-provider';

export const formatPrice = (price, style, currency) => {
  return new Intl.NumberFormat('en-US', {
    style,
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
  }).format(price);
};

const validationSchema = yup.object().shape({
  unitPrice: yup
    .number()
    .integer('Current price must be a whole number.')
    .transform((value) =>
      value === '' || Number.isNaN(value) ? undefined : value,
    )
    .min(1, 'Current price must be $1 or more.')
    .max(999999999, 'Current price must be less than $1,000,000,000'),
  quantity: yup
    .number()
    .transform((value) =>
      value === '' || Number.isNaN(value) ? undefined : value,
    )
    .moreThan(0, 'Allocation quantity must be 1 or more.'),

  shipmentDays: yup
    .number()
    .integer('Shipping days must be a whole number.')
    .moreThan(0, 'Shipping days must be between 0 and 999.')
    .lessThan(1000, 'Shipping days must be between 0 and 999.'),
  shipmentOrDelivery: yup.string(),
  deliveryDate: yup.date(),

  assemblyPoint: yup.string(),
  inspectionPoint: yup.string(),
  directFlag: yup.boolean(),
  vin: yup.string().max(17),
  additionalInformation: yup.string().max(500),

  coFirstName: yup
    .string()
    .matches(
      /^[A-Za-z]+$/,
      'CO last name must contain only alphabetic characters.',
    )
    .required('CO First Name is required.'),
  coLastName: yup
    .string()
    .matches(
      /^[A-Za-z]+$/,

      'CO last name must contain only alphabetic characters.',
    )
    .required('CO Last Name is required.'),
  coEmail: yup
    .string()
    .test('is-valid-email', 'Invalid email address.', (value) => {
      if (!value || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        return yup.string().email().isValidSync(value);
      }
      return true;
    }),
});

const NonSOPInfo = () => {
  const {
    lineTabData,
    setLineTabData,
    detailTabData,
    setDetailTabData,

    contractLine,
    vendorLocations,
  } = useContractLine();
  const purchaseTypeCode =
    contractLine?.contractHeader?.solicitation?.purchaseTypeCode;
  const isNonSOP = purchaseTypeCode === 'N' || purchaseTypeCode === 'O';
  const isMAS = purchaseTypeCode === 'M';
  const isExpress = purchaseTypeCode === 'E';

  const showAssemblyPointInput = isNonSOP;
  const showInspectionPointInput = isNonSOP || isMAS;
  const assemblyPointOptions =
    vendorLocations
      ?.filter((location) => location?.commonCode?.code === 'AsmPoint')
      .map((location) => ({
        label: `${location.associatedLocationName} - ${location.address1}, ${location.city}, ${location.state?.stateName} ${location.postalCode}`,
        value: location.id,
      })) || [];
  const inspectionPointOptions =
    vendorLocations
      ?.filter((location) => location?.commonCode?.code === 'InspPoint')
      .map((location) => ({
        label: `${location.associatedLocationName} - ${location.address1}, ${location.city}, ${location.state?.stateName} ${location.postalCode}`,
        value: location.id,
      })) || [];

  const defaultAssemblyPoint =
    contractLine?.associatedLocations?.[0]?.vendorAssemblyPointId;
  const defaultInspetionPoint =
    contractLine?.associatedLocations?.[0]?.vendorInspectionPointId;

  const defaultValues = {
    unitPrice:
      lineTabData?.contractLineFinancialRef?.unitPrice ||
      contractLine?.contractLineFinancialRef?.unitPrice ||
      '',
    quantity: lineTabData?.quantity || contractLine?.quantity || '',
    shipmentDays:
      detailTabData?.shipmentDays || contractLine?.shipmentDays || '',
    shipmentOrDelivery:
      lineTabData?.shipmentOrDelivery || contractLine?.shipmentOrDelivery || '',
    deliveryDate: lineTabData?.deliveryDate || contractLine?.deliveryDate || '',
    assemblyPoint: lineTabData?.assemblyPoint || defaultAssemblyPoint || '',
    inspectionPoint:
      lineTabData?.inspectionPoint || defaultInspetionPoint || '',
    isDirectFlag: lineTabData?.isDirectFlag || contractLine?.isDirectFlag || '',
    vin: lineTabData?.vin || contractLine?.vin || '',
    additionalInformation:
      lineTabData?.additionalInformation ||
      contractLine?.additionalInformation ||
      '',
    coFirstName: lineTabData?.coFirstName || contractLine?.coFirstName || '',
    coLastName: lineTabData?.coLastName || contractLine?.coLastName || '',
    coEmail: lineTabData?.coEmail || contractLine?.coEmail || '',
  };

  const { control, errors, setError } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const genLabel = (label, required) => (
    <span className="text-bold" data-testid={label}>
      {label} {required && <RequiredFieldIndicator />}
    </span>
  );

  const handleBlur = useCallback(
    (fieldName, value) => {
      setLineTabData((prev) => {
        const newValue = value;
        const currentValue =
          contractLine?.[fieldName] || lineTabData?.[fieldName];
        if (newValue === currentValue) {
          return prev;
        }
        return {
          ...prev,
          [fieldName]: newValue,
        };
      });
    },
    [contractLine, lineTabData],
  );

  const purchaseAvailabeleField = (
    <div className="grid-col flex-1">
      <Controller
        name="isPurchaseAvailable"
        control={control}
        render={({ value, onChange, onBlur }) => (
          <SelectDropdown
            name="isPurchaseAvailable"
            value={value}
            data-testid="isPurchaseAvailable"
            label={genLabel('Make available for ordering', false)}
            type="number"
            min={0}
            errorMessage={
              errors.isPurchaseAvailable && errors.isPurchaseAvailable.message
            }
            onBlur={onBlur}
            onChange={onChange}
            options={[
              { label: '- Select -', value: '' },
              { label: 'Yes', value: '1' },
              { label: 'No', value: '0' },
            ]}
          />
        )}
      />
    </div>
  );

  const isDirectFlag =
    lineTabData?.isDirectFlag || contractLine?.isDirectFlag || '';

  return (
    <div className="grid-container padding-0 margin-top-2">
      <div className="grid-row grid-gap-4 margin-bottom-4">
        {isExpress && (
          <div className="grid-col flex-1">
            <Controller
              name="vin"
              control={control}
              render={({ value, onChange, onBlur }) => (
                <TextInput
                  name="vin"
                  value={value === 0 ? emSpaceUnicode : value}
                  data-testid="vin"
                  type="string"
                  label={genLabel('VIN', false)}
                  errorMessage={errors.vin && errors.vin.message}
                  onChange={onChange}
                  onBlur={(e) => {
                    if (!errors.vin) {
                      onBlur(e);
                      handleBlur('vin', e.target?.value);
                    }
                  }}
                />
              )}
            />
          </div>
        )}
        <div className="grid-col flex-1">
          <Controller
            name="unitPrice"
            control={control}
            render={({ value, onChange, onBlur }) => (
              <TextInput
                name="unitPrice"
                value={value === 0 ? emSpaceUnicode : value}
                data-testid="current_price"
                type="number"
                min={0}
                label={genLabel('Current price', false)}
                prefix={<Icon iconName="attach_money" />}
                errorMessage={errors.unitPrice && errors.unitPrice.message}
                onChange={onChange}
                onBlur={(e) => {
                  if (!errors.unitPrice) {
                    onBlur(e);
                    setLineTabData((prev) => {
                      const newValue = value ? parseFloat(value) : '';
                      const currentValue =
                        contractLine?.contractLineFinancialRef?.unitPrice ||
                        lineTabData?.contractLineFinancialRef?.unitPrice;
                      if (newValue === currentValue) {
                        return prev;
                      }
                      return {
                        ...prev,
                        contractLineFinancialRef: {
                          ...prev.contractLineFinancialRef,
                          unitPrice: newValue,
                        },
                      };
                    });
                  }
                }}
              />
            )}
          />
        </div>
        <div className="grid-col flex-1">
          <Controller
            name="quantity"
            control={control}
            render={({ value, onChange, onBlur }) => (
              <TextInput
                name="quantity"
                value={value}
                data-testid="quantity"
                label={genLabel('Quantity', false)}
                type="number"
                min={0}
                errorMessage={errors.quantity && errors.quantity.message}
                onChange={onChange}
                onBlur={(e) => {
                  if (!errors.quantity) {
                    onBlur(e);
                    handleBlur('quantity', e.target?.value);
                  }
                }}
              />
            )}
          />
        </div>

        {!isExpress && purchaseAvailabeleField}
        <div className="grid-col flex-1" />
        <div className="grid-col flex-1" />
      </div>

      <div className="grid-row grid-gap-4 margin-bottom-4">
        <div className="grid-col">
          <Controller
            name="shipmentDays"
            control={control}
            render={({ value, onChange, onBlur }) => (
              <TextInput
                name="shipmentDays"
                value={value}
                data-testid="shipmentDays"
                label={genLabel('Shipment days', false)}
                type="number"
                min={0}
                errorMessage={
                  errors.shipmentDays && errors.shipmentDays.message
                }
                onChange={onChange}
                onBlur={(e) => {
                  if (!errors.shipmentDays) {
                    onBlur(e);
                    setDetailTabData((prev) => {
                      const newValue = value;
                      const currentValue =
                        contractLine?.shipmentDays ||
                        detailTabData?.shipmentDays;
                      if (newValue === currentValue) {
                        return prev;
                      }
                      return {
                        ...prev,
                        shipmentDays: newValue,
                      };
                    });
                  }
                }}
              />
            )}
          />
        </div>
        <div className="grid-col">
          <Controller
            name="shipmentOrDelivery"
            control={control}
            render={({ value, onChange, onBlur }) => (
              <SelectDropdown
                name="shipmentOrDelivery"
                value={value}
                data-testid="shipmentOrDelivery"
                label={genLabel('Delivery/Shipment', false)}
                type="number"
                min={0}
                errorMessage={
                  errors.shipmentOrDelivery && errors.shipmentOrDelivery.message
                }
                onBlur={(e) => {
                  if (!errors.shipmentOrDelivery) {
                    onBlur(e);
                    handleBlur('shipmentOrDelivery', e.target?.value);
                  }
                }}
                onChange={onChange}
                options={[
                  { label: '- Select -', value: '' },
                  { label: 'Shipment', value: 'S' },
                  { label: 'Delivery', value: 'D' },
                ]}
              />
            )}
          />
        </div>
        <div className="grid-col">
          <Controller
            name="deliveryDate"
            control={control}
            render={({ value, onChange, onBlur }) => (
              <DatePicker
                id="deliveryDate"
                name="deliveryDate"
                defaultValue={value}
                labelClass="text-bold"
                label="Delivery date"
                onChange={onChange}
                onBlur={(e) => {
                  if (!errors.deliveryDate) {
                    onBlur(e);
                    handleBlur('deliveryDate', e.target?.value);
                  }
                }}
              />
            )}
          />
        </div>
        <div className="grid-col flex-1" />
        <div className="grid-col flex-1" />
      </div>

      <div className="grid-row grid-gap-4 margin-bottom-4">
        {showAssemblyPointInput && (
          <div className="grid-col">
            <Controller
              name="assemblyPoint"
              control={control}
              render={({ value, onChange, onBlur }) => (
                <SelectDropdown
                  name="assemblyPoint"
                  value={value}
                  data-testid="assemblyPoint"
                  label={genLabel('Assembly point', false)}
                  type="number"
                  min={0}
                  errorMessage={
                    errors.assemblyPoint && errors.assemblyPoint.message
                  }
                  onBlur={(e) => {
                    if (!errors.assemblyPoint) {
                      onBlur(e);
                      setDetailTabData((prevData) => ({
                        ...prevData,
                        associatedLocations: [
                          {
                            ...(prevData?.associatedLocations?.[0] || []),
                            vendorAssemblyPointId: parseInt(
                              e?.target?.value,
                              10,
                            ),
                          },
                        ],
                      }));
                    }
                  }}
                  onChange={onChange}
                  options={[
                    { label: '- Select -', value: '' },
                    ...assemblyPointOptions,
                  ]}
                />
              )}
            />
          </div>
        )}
        {showInspectionPointInput && (
          <div className="grid-col flex-1">
            <Controller
              name="inspectionPoint"
              control={control}
              render={({ value, onChange, onBlur }) => (
                <SelectDropdown
                  name="inspectionPoint"
                  value={value}
                  data-testid="inspectionPoint"
                  label={genLabel('Inspection point', false)}
                  type="number"
                  min={0}
                  errorMessage={
                    errors.inspectionPoint && errors.inspectionPoint.message
                  }
                  onBlur={(e) => {
                    if (!errors.inspectionPoint) {
                      onBlur(e);
                      setDetailTabData((prevData) => ({
                        ...prevData,
                        associatedLocations: [
                          {
                            ...(prevData?.associatedLocations?.[0] || []),
                            vendorInspectionPointId: parseInt(
                              e?.target?.value,
                              10,
                            ),
                          },
                        ],
                      }));
                    }
                  }}
                  onChange={onChange}
                  options={[
                    { label: '- Select -', value: '' },
                    ...inspectionPointOptions,
                  ]}
                />
              )}
            />
          </div>
        )}

        <div className="grid-col flex-1 margin-top-3">
          {genLabel('Direct flag', false)}
          <div className="padding-top-1">{isDirectFlag || 'No'}</div>
        </div>

        {isExpress && purchaseAvailabeleField}
        {!isExpress && <div className="grid-col flex-1" />}
        <div className="grid-col flex-1" />
        {!showAssemblyPointInput && <div className="grid-col flex-1" />}
        {!showInspectionPointInput && <div className="grid-col flex-1" />}
      </div>

      <div className="grid-row grid-gap-4 margin-bottom-4">
        <div className="grid-col">
          <Controller
            name="additionalInformation"
            control={control}
            render={({ value, onChange, onBlur }) => (
              <TextInput
                name="additionalInformation"
                data-testid="additionalInformation"
                type="textarea"
                label={genLabel('Additional information', false)}
                characterLimit={500}
                value={value}
                onChange={onChange}
                onBlur={(e) => {
                  if (!errors.additionalInformation) {
                    onBlur(e);
                    handleBlur('additionalInformation', e.target?.value);
                  }
                }}
              />
            )}
          />
        </div>
      </div>

      <div
        className="title-s-caps text-primary margin-top-6"
        style={{ borderBottom: '1px solid #DFE1E2' }}
      >
        CONTRACTING OFFICER INFO
      </div>

      <div className="grid-row grid-gap-4 margin-bottom-2">
        <div className="grid-col flex-1">
          <Controller
            name="coFirstName"
            control={control}
            render={({ value, onChange, onBlur }) => (
              <TextInput
                name="coFirstName"
                value={value === 0 ? emSpaceUnicode : value}
                data-testid="coFirstName"
                type="string"
                label={genLabel('First name', true)}
                errorMessage={errors.coFirstName && errors.coFirstName.message}
                onChange={onChange}
                onBlur={(e) => {
                  if (!errors.coFirstName) {
                    onBlur(e);
                    handleBlur('coFirstName', e.target?.value);
                  }
                }}
              />
            )}
          />
        </div>
        <div className="grid-col flex-1">
          <Controller
            name="coLastName"
            control={control}
            render={({ value, onChange, onBlur }) => (
              <TextInput
                name="coLastName"
                value={value === 0 ? emSpaceUnicode : value}
                data-testid="coLastName"
                label={genLabel('Last name', true)}
                type="string"
                errorMessage={errors.coLastName && errors.coLastName.message}
                onChange={onChange}
                onBlur={(e) => {
                  if (!errors.coLastName) {
                    onBlur(e);
                    handleBlur('coLastName', e.target?.value);
                  }
                }}
              />
            )}
          />
        </div>
        <div className="grid-col flex-1" />
        <div className="grid-col flex-1" />
        <div className="grid-col flex-1" />
      </div>

      <div className="grid-row grid-gap-4">
        <div className="grid-col flex-1">
          <Controller
            name="coEmail"
            control={control}
            render={({ value, onChange, onBlur }) => (
              <TextInput
                name="coEmail"
                value={value === 0 ? emSpaceUnicode : value}
                data-testid="coEmail"
                label={genLabel('Email', false)}
                type="string"
                errorMessage={errors.coEmail && errors.coEmail.message}
                onChange={onChange}
                onBlur={(e) => {
                  onBlur(e);
                  if (!value || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                    handleBlur('coEmail', e.target?.value);
                  } else {
                    setError('coEmail', {
                      message: 'Invalid email address.',
                    });
                  }
                }}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default NonSOPInfo;
