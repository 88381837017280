/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import PropTypes from 'prop-types';
import {
  connectModal,
  useModal,
  Button,
  Spinner,
} from '@gsa/afp-component-library';
import './make-model-table.scss';
import { useFvsPreBidContext } from '../../../fvs-pre-bid-selection-provider';
import MakeAndModelSearchPanel from './make-and-model-search-panel';
import MakeAndModelTableBody from './make-and-model-table-body';
import ModelTypeAhead from './model-type-ahead';
import DeleteMakeAndModel from './delete-make-model-modal';
import { GET_VENDORS_BY_PERMISSION } from '../../../../../services/data-store';

const MakeAndModel = ({ standardItemId, tags }) => {
  const {
    bodyMake,
    bodyMakeInputValue,
    setBodyMakeInputValue,
    bodyModel,
    bodyModelInputValue,
    setBodyModelInputValue,
    chassisMake,
    chassisMakeInputValue,
    setChassisMakeInputValue,
    chassisModel,
    chassisModelInputValue,
    setChassisModelInputValue,
    deleteMakeAndModelById,
    preBidSelectionsMakeAndModel,
    updatePreBidSelectionsWithMakeAndMode,
    findAllMakeAndModelByStandardItemId,
    preBidSelectionForStandardItem,
    modelYear,
    setModelYear,
    setHasChassis,
    hasChassis,
  } = useFvsPreBidContext();

  const { isOpen, openModal, closeModal } = useModal();

  const { currentUser } = useCurrentUser();

  const [makeAndModelToBeDeleted, setMakeAndModeToBeDeleted] = useState('');
  const [isShowMore, setShowMore] = useState(false);
  const [filterData, setFilterData] = useState([]);

  const updateTableData = (isButtonClicked) => {
    const list = preBidSelectionsMakeAndModel?.filter(
      ({ make, model }) =>
        !isEmpty(make?.bodyMake) || !isEmpty(model?.modelName),
    );

    if (!isButtonClicked) {
      setFilterData(list.slice(0, 3));
      setShowMore(list.length > 3);
      return;
    }

    if (!isShowMore) {
      setFilterData(list.slice(0, 3));
      setShowMore(list.length > 3);
    } else {
      setFilterData(list);
      setShowMore(list.length < 3);
    }
  };

  const { loading: isVendorDataLoading, data: vendorData } = useQuery(
    GET_VENDORS_BY_PERMISSION,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        operation: 'view',
        subject: 'Vendor',
      },
      skip: !currentUser,
    },
  );
  // if SIN tag is NUll set it to false
  const isSINHasChassis = tags?.value?.includes('REQ_CHASSIS_MOD') || false;

  useEffect(() => {
    updateTableData();
  }, [preBidSelectionsMakeAndModel]);

  useEffect(() => {
    setHasChassis(isSINHasChassis);
  }, [tags]);

  useEffect(() => {
    if (vendorData && vendorData?.getVendorsByPermission?.length) {
      const vendor = vendorData?.getVendorsByPermission[0];
      findAllMakeAndModelByStandardItemId({
        variables: {
          filters: {
            operator: 'AND',
            value: [
              {
                key: '$preBidSelection.standard_item_id$',
                operator: 'EQ',
                value: Number.parseInt(standardItemId, 10),
              },
              {
                key: '$preBidSelection.vendor_id$',
                operator: 'EQ',
                value: vendor?.id,
              },
            ],
          },
          order: 'createdAt DESC',
        },
      });
    }
  }, [standardItemId, vendorData]);

  const canAddMakeAndMode = () => {
    if (isSINHasChassis) {
      return (
        bodyMakeInputValue &&
        bodyModelInputValue &&
        chassisMakeInputValue &&
        chassisModelInputValue &&
        modelYear
      );
    }
    return bodyMakeInputValue && bodyModelInputValue && modelYear;
  };

  const handleAddMakeAndModel = async () => {
    if (
      canAddMakeAndMode() &&
      vendorData &&
      vendorData?.getVendorsByPermission?.length
    ) {
      const vendorId = vendorData?.getVendorsByPermission[0]?.id;
      await updatePreBidSelectionsWithMakeAndMode({
        variables: {
          input: isSINHasChassis
            ? {
                preBidSelectionId: Number.parseInt(
                  preBidSelectionForStandardItem?.id,
                  10,
                ),
                standardItemId: Number.parseInt(standardItemId, 10),
                makeCode: parseInt(bodyMake?.value, 10),
                modelCode: bodyModel?.value,
                modelYear: parseInt(modelYear, 10),
                chassisMakeCode: parseInt(chassisMake?.value, 10),
                chassisModelCode: chassisModel?.value,
                makeName: bodyMakeInputValue,
                modelName: bodyModelInputValue,
                chassisMakeName: chassisMakeInputValue,
                chassisModelName: chassisModelInputValue,
                metaData: { bodyMake, bodyModel, hasChassis: isSINHasChassis },
              }
            : {
                preBidSelectionId: Number.parseInt(
                  preBidSelectionForStandardItem?.id,
                  10,
                ),
                standardItemId: Number.parseInt(standardItemId, 10),
                makeCode: parseInt(bodyMake?.value, 10),
                modelCode: bodyModel?.value,
                modelYear: parseInt(modelYear, 10),
                makeName: bodyMakeInputValue,
                modelName: bodyModelInputValue,
                metaData: { bodyMake, bodyModel, hasChassis: isSINHasChassis },
              },
        },
      });

      await findAllMakeAndModelByStandardItemId({
        variables: {
          filters: {
            operator: 'AND',
            value: [
              {
                key: '$preBidSelection.standard_item_id$',
                operator: 'EQ',
                value: Number.parseInt(standardItemId, 10),
              },
              {
                key: '$preBidSelection.vendor_id$',
                operator: 'EQ',
                value: vendorId,
              },
            ],
          },
          order: 'createdAt DESC',
        },
      });

      setBodyMakeInputValue('');
      setBodyModelInputValue('');
      setChassisMakeInputValue('');
      setChassisModelInputValue('');
      setModelYear('');
    }
  };
  const tableWidth = hasChassis ? 'chassisWidth' : 'makeAndModelWidth';

  const handleDelete = () => {
    deleteMakeAndModelById({
      variables: { makeAndModelId: parseInt(makeAndModelToBeDeleted?.id, 10) },
    });
    closeModal();
  };

  const handleShow = (onButtonClicked) => {
    updateTableData(onButtonClicked);
  };

  const ConnectedDeleteModal = connectModal(() => (
    <DeleteMakeAndModel
      makeAndModelToBeDeleted={makeAndModelToBeDeleted}
      closeModal={closeModal}
      onSubmit={() => handleDelete()}
    />
  ));
  return isVendorDataLoading ? (
    <Spinner />
  ) : (
    <>
      <ConnectedDeleteModal isOpen={isOpen} closeModal={closeModal} />
      <MakeAndModelSearchPanel
        hasChassis={hasChassis}
        handleAddMakeAndModel={handleAddMakeAndModel}
      />
      <div data-testid="table" className={tableWidth}>
        {filterData.map(
          ({
            make,
            model,
            chassisMake: chassisMakeValue,
            chassisModel: chassisModelValue,
            makeModelApproval,
            id,
            modelYear: year,
          }) => {
            // Determine approval status based on the given conditions
            const hasNoChassis = !chassisMakeValue && !chassisModelValue;
            const isMakeApproved =
              make?.approvalStatus === 'Approved' ||
              make?.fleetStatus === 'Approved';
            const isModelApproved =
              model?.approvalStatus === 'Approved' ||
              model?.fleetStatus === 'Approved';

            const makeModelApprovalStatus =
              hasNoChassis && isMakeApproved && isModelApproved
                ? 'Approved'
                : makeModelApproval?.fleetApprovalStatus;
            return (
              <MakeAndModelTableBody
                key={model?.modelName}
                id={id}
                deleteMakeAndModelById={deleteMakeAndModelById}
                openModal={openModal}
                setMakeAndModeToBeDeleted={setMakeAndModeToBeDeleted}
                modelName={model?.modelName}
                makeName={make?.makeName}
                makeApprovalStatus={make?.approvalStatus}
                modelApprovalStatus={model?.approvalStatus}
                hasChassis={hasChassis}
                makeChassis={chassisMakeValue?.makeName}
                modelChassis={chassisModelValue?.modelName}
                chassisMakeApprovalStatus={chassisMakeValue?.approvalStatus}
                chassisModelApprovalStatus={chassisModelValue?.approvalStatus}
                makeModelApprovalStatus={makeModelApprovalStatus}
                modelYear={year}
              />
            );
          },
        )}
      </div>
      {preBidSelectionsMakeAndModel.length >= 4 && (
        <Button
          data-testid="show-more-button"
          type="button"
          variant="unstyled"
          onClick={() => handleShow(true)}
          className="margin-top-1"
          label={isShowMore ? 'See More' : 'See Less'}
        />
      )}
    </>
  );
};

ModelTypeAhead.propTypes = {
  standardItemId: PropTypes.string.isRequired,
};

export default MakeAndModel;
