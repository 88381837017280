import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import {
  Spinner,
  ErrorMessage,
  AFPTable,
  PageTitle,
  Label,
  Pagination,
  EmptyState,
  Button,
  useModal,
  connectModal,
  Link,
} from '@gsa/afp-component-library';

import { emDashUnicode } from '../../../utilities/constants';
import Breadcrumbs from '../../../widgets/breadcrumbs';
import { GET_CONTRACT_HEADER_BY_ID } from '../contract-header/contract-header.gql';
import ToastMessage from '../../../components/Toast/toast';
import ContractLineActions from './contract-line-action';
import UpdateLineItemsModal from './update-contract-line-modal';
import DeleteContractLineModal from './delete-items-modal';
import ReviewItemsModal from './review-items-modal';
import getColumns from './columns';
import {
  GET_CONTRACT_LINES_BY_CRITERIA,
  DELETE_CONTRACT_LINE_BY_ID,
} from './lines-query';

import './contract-line-listing.scss';
import ContractLineListingSearch, {
  DEFAULT_FILTERS,
} from './contract-line-listing-search';

const StandardFieldset = ({ label, children, name }) => (
  <fieldset
    className="usa-fieldset usa-fieldset secondary-background"
    name={name}
  >
    {label && (
      <div className="text-primary text-bold text-uppercase">{label}</div>
    )}
    {children}
  </fieldset>
);

StandardFieldset.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
};

StandardFieldset.defaultProps = {
  label: undefined,
};

const StandardFieldsetRow = ({ children }) => (
  <div className="grid-row margin-bottom-4 grid-gap-4">{children}</div>
);

StandardFieldsetRow.propTypes = {
  children: PropTypes.node,
};

StandardFieldsetRow.defaultProps = {
  children: null,
};

const StandardFieldsetRowCol = ({ colWidth, children, label, data }) => (
  <div className={`col grid-col-${colWidth}`} aria-label={label}>
    {label && <Label>{label}</Label>}
    {data && <span>{data}</span>}
    {children}
  </div>
);

StandardFieldsetRowCol.propTypes = {
  colWidth: PropTypes.number,
  children: PropTypes.node,
  label: PropTypes.string,
  data: PropTypes.node,
};

StandardFieldsetRowCol.defaultProps = {
  colWidth: 1,
  children: null,
  label: null,
  data: null,
};

const contractShape = PropTypes.shape({
  contractNumber: PropTypes.string,
  contractYear: PropTypes.number,
  formalContractNumber: PropTypes.string,
  solicitation: PropTypes.shape({
    solicitationPeriods: PropTypes.arrayOf(PropTypes.shape({})),
    solicitationNumberLegacy: PropTypes.string,
    solicitationNumber: PropTypes.string,
  }),
  fleetAwarded: PropTypes.bool,
  contractUpiid: PropTypes.string,
  vendor: PropTypes.shape({
    id: PropTypes.string,
    vendorName: PropTypes.string,
  }),
  publishedInFleet: PropTypes.bool,
});

const ContractDetails = ({ contract }) => (
  <StandardFieldset name="contract">
    <StandardFieldsetRow>
      <StandardFieldsetRowCol
        colWidth={2}
        label="Internal contract number"
        data={contract.contractNumber}
      />
      <StandardFieldsetRowCol
        colWidth={2}
        label="Formal contract number"
        data={contract.formalContractNumber}
      />
      <StandardFieldsetRowCol
        colWidth={2}
        label="Solicitation uPIID"
        data={contract.solicitation?.solicitationNumber}
      />
      <StandardFieldsetRowCol
        colWidth={2}
        label="Federal vehicle standards year"
        data={contract.contractYear}
      />
      <StandardFieldsetRowCol
        colWidth={2}
        label="Period"
        data={contract.solicitation?.solicitationPeriods?.length}
      />
      <StandardFieldsetRowCol
        colWidth={2}
        label="Awarded in GSA Fleet"
        data={contract.fleetAwarded ? 'Yes' : 'No'}
      />
      {/* <StandardFieldsetRowCol
        colWidth={2}
        label="Publish"
        data={contract.publishedInFleet}
      /> */}
    </StandardFieldsetRow>
  </StandardFieldset>
);

ContractDetails.propTypes = {
  contract: contractShape.isRequired,
};

const RowSubComponent = ({ row: { original = {} }, isSop }) => {
  const { contractHeaderId } = useParams();
  return (
    <section className="grid-row grid-gap padding-left-5">
      <section className="grid-col-4">
        <h3 className="text-primary margin-top-0">CONTRACT LINE DETAILS</h3>
        <section className="display-flex flex-column">
          <p className="border-bottom border-base-lighter margin-top-0">
            <RouterLink
              to={`/catalog/contract/${contractHeaderId}/contract-line-template/${original?.id}?tabIndex=0`}
              className="text-bold margin-left-1"
            >
              Contract line
            </RouterLink>
          </p>
          {isSop && (
            <>
              <p className="border-bottom border-base-lighter margin-top-0">
                <RouterLink
                  to={`/catalog/contract/${contractHeaderId}/contract-line-template/${original?.id}?tabIndex=1`}
                  className="text-bold margin-left-1"
                >
                  Contract line details
                </RouterLink>
              </p>
              <p className="border-bottom border-base-lighter margin-top-0">
                <RouterLink
                  to={`/catalog/contract/${contractHeaderId}/contract-line-template/${original?.id}?tabIndex=2`}
                  className="text-bold margin-left-1"
                >
                  Minimum requirements
                </RouterLink>
              </p>
              <p className="border-bottom border-base-lighter margin-top-0">
                <RouterLink
                  to={`/catalog/contract/${contractHeaderId}/contract-line-template/${original?.id}?tabIndex=3`}
                  className="text-bold margin-left-1"
                >
                  Optional equipment
                </RouterLink>
              </p>
              <p className="border-bottom border-base-lighter margin-top-0">
                <RouterLink
                  to={`/catalog/contract/${contractHeaderId}/contract-line-template/${original?.id}?tabIndex=4`}
                  className="text-bold margin-left-1"
                >
                  Engine and fuel
                </RouterLink>
              </p>
              <p className="border-bottom border-base-lighter margin-top-0">
                <RouterLink
                  to={`/catalog/contract/${contractHeaderId}/contract-line-template/${original?.id}?tabIndex=5`}
                  className="text-bold margin-left-1"
                >
                  Colors
                </RouterLink>
              </p>
              <p className="border-bottom border-base-lighter margin-top-0">
                <RouterLink
                  to={`/catalog/contract/${contractHeaderId}/contract-line-template/${original?.id}?tabIndex=6`}
                  className="text-bold margin-left-1"
                >
                  Documentation
                </RouterLink>
              </p>
            </>
          )}
        </section>
      </section>
      <section className="grid-col">
        <h3 className="text-primary margin-top-0">ADDITIONAL INFORMATION</h3>
        <section className="display-flex flex-column">
          {isSop ? (
            <>
              <p className="border-bottom border-base-lighter margin-top-0">
                <span className="text-bold">Program:</span>
                <span className="margin-left-1">
                  {original?.programName || emDashUnicode}
                </span>
              </p>
              <p className="border-bottom border-base-lighter margin-top-0">
                <span className="text-bold">Vehicle group:</span>
                <span className="margin-left-1">
                  {`${
                    original?.standardItem?.vehicleGroup?.code || emDashUnicode
                  } - ${
                    original?.standardItem?.vehicleGroup?.title || emDashUnicode
                  }`}
                </span>
              </p>
              <p className="border-bottom border-base-lighter margin-top-0">
                <span className="text-bold">Vehicle type:</span>
                <span className="margin-left-1">
                  {`${original?.standardItem?.vehicleType || emDashUnicode} - ${
                    original?.standardItem?.vehicleTypeCode?.title ||
                    emDashUnicode
                  }`}
                </span>
              </p>
            </>
          ) : (
            <p className="border-bottom border-base-lighter margin-top-0">
              <span className="text-bold">Additional information:</span>
              <span className="margin-left-1">
                {`${original?.additionalInformation || emDashUnicode}`}
              </span>
            </p>
          )}

          <p className="border-bottom border-base-lighter margin-top-0">
            <span className="text-bold">Standard item:</span>
            <span className="margin-left-1">
              {`${
                original?.standardItem?.standardItemNumber || emDashUnicode
              } - ${original?.standardItem?.title || emDashUnicode}`}
            </span>
          </p>
          <p className="border-bottom border-base-lighter margin-top-0">
            <span className="text-bold">Model year:</span>
            <span className="margin-left-1">
              {`${original?.modelYear || emDashUnicode}`}
            </span>
          </p>
        </section>
      </section>
    </section>
  );
};

RowSubComponent.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      standardItem: PropTypes.shape({
        vehicleGroup: PropTypes.shape({
          code: PropTypes.string,
          title: PropTypes.string,
        }),
        vehicleType: PropTypes.string,
        vehicleTypeCode: PropTypes.shape({
          title: PropTypes.string,
        }),
        standardItemNumber: PropTypes.string,
        title: PropTypes.string,
      }),
    }),
  }).isRequired,
  isSop: PropTypes.bool.isRequired,
};

const TableWrapper = ({ tableProps, paginationProps }) => (
  <>
    <AFPTable {...tableProps} key={tableProps.columns.length} />
    {tableProps.data && tableProps.data.length > 0 ? (
      <Pagination {...paginationProps} />
    ) : (
      <div className="text-center margin-top-neg-2 height-full">
        <EmptyState
          hasBackground
          containerStyles="padding-top-9 height-full"
          topText={
            <p aria-label="There are no contract lines associated with this contract.">
              <strong>
                There are no contract lines associated with this contract.
              </strong>
            </p>
          }
        />
      </div>
    )}
  </>
);

TableWrapper.propTypes = {
  tableProps: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
    columns: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  paginationProps: PropTypes.shape({
    itemsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
    onPageChange: PropTypes.func,
    variant: PropTypes.string,
    itemsPerPage: PropTypes.number,
    currentPage: PropTypes.number,
    itemsCount: PropTypes.number,
    isReset: PropTypes.bool,
  }).isRequired,
};

const ContractLineListing = () => {
  const { contractHeaderId } = useParams();
  const [alert, setAlert] = useState(null);
  const [paginationState, setPaginationState] = useState({
    limit: 10000,
    offset: 0,
    currentPage: 1,
  });
  const [selectedItems, setSelectedItems] = useState([]);
  const [updatedData, setUpdatedData] = useState({});

  const { limit, offset, currentPage } = paginationState;

  const {
    loading: loadingLines,
    error: errorLines,
    data: dataLines,
    refetch: refetchLines,
  } = useQuery(GET_CONTRACT_LINES_BY_CRITERIA, {
    fetchPolicy: 'network-only',
    variables: {
      criteria: { contractHeaderId },
      limit,
      offset,
      order: 'scheduleLine DESC',
    },
  });

  const { data: dataHeader } = useQuery(GET_CONTRACT_HEADER_BY_ID, {
    variables: { contractHeaderId },
  });

  const [deleteContractLine] = useMutation(DELETE_CONTRACT_LINE_BY_ID);

  const contractLines = dataLines?.getContractLinesByCriteria?.rows || [];
  // const totalCount = dataLines?.getContractLinesByCriteria?.rows?.length || 0;
  const contractHeader = dataHeader?.getContractHeaderById;
  const contract = contractHeader || {};
  const vendor = contractHeader?.vendor || {};
  const vendorId = contractHeader?.vendor?.id || '';

  const isSop =
    contractHeader?.solicitation?.purchaseTypeCode === 'S' ||
    contractHeader?.solicitation?.purchaseTypeCode === 'P';
  const program = contractHeader?.solicitation?.programs || [];

  const [contractLinesData, setContractLinesData] = useState(contractLines);

  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [filteredContractLines, setFilteredContractLines] = useState(
    contractLinesData,
  );
  const [programIds, setProgramIds] = useState({});

  const getProgramName = (programNames, { stdId, solId }) => {
    let result;
    if (stdId && solId) {
      result = Object.entries(programNames)
        ?.map(([key, value]) => {
          if (
            value.stdIds?.includes(stdId) &&
            value.solLines?.includes(solId)
          ) {
            return key;
          }
          return null;
        })
        .filter((item) => item)[0];
      return result;
    }
    if (solId) {
      result = Object.entries(programNames)
        ?.map(([key, value]) => {
          if (value.solLines?.includes(solId)) {
            return key;
          }
          return null;
        })
        .filter((item) => item)[0];
      return result;
    }
    if (stdId) {
      result = Object.entries(programNames)
        ?.map(([key, value]) => {
          if (value.stdIds?.includes(stdId)) {
            return key;
          }
          return null;
        })
        .filter((item) => item)[0];
      return result;
    }

    return result;
  };
  useEffect(() => {
    const programNamesWithIds = program?.reduce((a, c) => {
      return {
        ...a,
        [c.program]: {
          stdIds: c.solicitationLines.map((item) =>
            String(item.standardItem.standardItemNumber),
          ),
          solLines: c.solicitationLines.map((item) => String(item.id)),
        },
      };
    }, {});

    const tempContractLines = contractLines.map((line) => ({
      ...line,
      programName: getProgramName(programNamesWithIds, {
        // stdId: line.standardItem.standardItemNumber,
        stdId: null,
        solId: String(line.solicitationLineId),
      }),
    }));
    if (Object.keys(program).length) {
      setContractLinesData(tempContractLines);
    }
  }, [programIds, contractLines]);

  useEffect(() => {
    const programNamesWithIds = program?.reduce((a, c) => {
      return {
        ...a,
        [c.program]: {
          stdIds: c.solicitationLines.map((item) =>
            String(item.standardItem.standardItemNumber),
          ),
          solLines: c.solicitationLines.map((item) => String(item.id)),
        },
      };
    }, {});
    if (
      programNamesWithIds &&
      Object.keys(programIds)?.length !== program.length
    ) {
      setProgramIds(programNamesWithIds);
    }
  }, [program]);

  const {
    isOpen: updateContractLineIsOpen,
    openModal: updateContractLineOpenModal,
    closeModal: updateContractLineCloseModal,
  } = useModal();
  const {
    isOpen: reviewItemsIsOpen,
    openModal: reviewItemsOpenModal,
    closeModal: reviewItemsCloseModal,
  } = useModal();
  const {
    isOpen: deleteContractLineIsOpen,
    openModal: deleteContractLineOpenModal,
    closeModal: deleteContractLineCloseModal,
  } = useModal();

  const ConnectedUpdateLineItemModal = connectModal(UpdateLineItemsModal);
  const ConnectedReviewItemsModal = connectModal(ReviewItemsModal);
  const ConnectedDeleteContractLineModal = connectModal(
    DeleteContractLineModal,
  );

  const handleRowSelect = ({ selectedFlatRows }) => {
    const rows = selectedFlatRows.map((row) => row.original);
    setSelectedItems(rows);
  };

  const handleDelete = async () => {
    try {
      await Promise.all(
        selectedItems.map((item) =>
          deleteContractLine({
            variables: { contractLineId: parseInt(item.id, 10) },
          }),
        ),
      );
      const deletedItems = selectedItems.map((item) => item.scheduleLine);
      const message =
        deletedItems.length === 1
          ? `The contract line ${deletedItems[0]} has been deleted.`
          : `The contract lines ${deletedItems.join(', ')} have been deleted.`;
      setAlert({
        type: 'success',
        message: <strong>{message}</strong>,
      });
      setSelectedItems([]);
      await refetchLines();
    } catch (err) {
      setAlert({
        type: 'error',
        message: `Failed to delete contract lines: ${err.message}`,
      });
    }
    deleteContractLineCloseModal();
  };

  const breadcrumbs = (
    <Breadcrumbs
      path={[
        {
          location: `${window.AFP_CONFIG.appURLs.home}/home`,
          label: 'Home',
        },
        {
          location: `/catalog/contract`,
          label: 'Contracts',
        },
        {
          location: `/catalog/contract/contract-header/${contractHeaderId}`,
          label: 'Contract header',
        },
      ]}
      current="Contract line listing"
    />
  );

  const columns = useMemo(() => getColumns({ isSop }), [isSop]);

  return (
    <div id="contract-line-listing">
      {/* <StandardItemBodyChassisPanel hasChassis /> */}
      {breadcrumbs}
      {loadingLines && <Spinner data-testid="contract-line-listing-spinner" />}
      {errorLines && (
        <ErrorMessage>An error occurred: {errorLines.message}</ErrorMessage>
      )}
      {alert && (
        <ToastMessage
          type={alert.type}
          message={alert.message}
          onClose={() => setAlert(null)}
          closable
          className="margin-bottom-2"
        />
      )}
      {!loadingLines && !errorLines && (
        <>
          <div className="grid-row grid-gap margin-bottom-2">
            <div className="grid-col-10">
              <PageTitle
                title={
                  <>
                    Contract uPIID:
                    {contract.contractUpiid || emDashUnicode}
                  </>
                }
              />
              <div>
                <span>Contractor: </span>
                <Link
                  href={`${window.AFP_CONFIG.appURLs.home}/vendor/details/${vendorId}`}
                >
                  {vendor.vendorName}
                </Link>
              </div>
            </div>
            <div className="grid-col-2 display-flex flex-align-end flex-justify-end">
              <ContractLineActions
                contractHeader={contractHeader}
                contractLines={contractLines}
                setAlert={setAlert}
                refetchLines={refetchLines}
              />
            </div>
          </div>
          <ContractDetails contract={contract} />
          <ContractLineListingSearch
            contractLines={contractLinesData}
            filters={filters}
            setFilters={setFilters}
            setFilteredContractLines={setFilteredContractLines}
            isSop={!!isSop}
          />
          <div className="grid-row grid-gap margin-top-2 margin-bottom-2">
            <div className="grid-col-12">
              {isSop && (
                <Button
                  label="Update selected lines"
                  variant="primary"
                  onClick={updateContractLineOpenModal}
                  disabled={selectedItems.length === 0}
                />
              )}
              <Button
                label="Delete selected lines"
                variant="danger"
                onClick={deleteContractLineOpenModal}
                disabled={selectedItems.length === 0}
                className="margin-left-2"
              />
            </div>
          </div>
          <TableWrapper
            tableProps={{
              data: filteredContractLines,
              columns,
              defaultSort: 'scheduleLine asc',
              expandable: !!contractLines.length,
              selectable: !!contractLines.length,
              onRowSelect: handleRowSelect,
              renderRowSubComponent: contractLines.length
                ? (props) => <RowSubComponent {...props} isSop={isSop} />
                : undefined,
            }}
            paginationProps={{
              itemsPerPageOptions: [50, 100, 150, 200],
              onPageChange: (newPage, itemsPerPage) => {
                setPaginationState({
                  ...paginationState,
                  currentPage: newPage,
                  offset: (newPage - 1) * itemsPerPage,
                  limit: itemsPerPage,
                });
              },
              variant: 'advanced',
              itemsPerPage: limit,
              currentPage,
              itemsCount: filteredContractLines.length ?? 0,
              isReset: false,
            }}
          />

          <ConnectedUpdateLineItemModal
            isOpen={updateContractLineIsOpen}
            closeModal={updateContractLineCloseModal}
            lineItems={selectedItems}
            contractHeaderId={contractHeaderId}
            headerData={contract}
            onComplete={(formData, items) => {
              setUpdatedData({ formData, items });
              setAlert({
                type: 'success',
                message: (
                  <div>
                    <strong>
                      {items.length} contract lines have been updated.{' '}
                    </strong>
                    <Button
                      onClick={reviewItemsOpenModal}
                      variant="unstyled"
                      label="Review updates"
                    />
                  </div>
                ),
              });
              refetchLines();
            }}
          />

          <ConnectedReviewItemsModal
            isOpen={reviewItemsIsOpen}
            closeModal={reviewItemsCloseModal}
            items={updatedData?.items}
            data={updatedData?.formData}
          />
          <ConnectedDeleteContractLineModal
            isOpen={deleteContractLineIsOpen}
            closeModal={deleteContractLineCloseModal}
            onDelete={handleDelete}
            selectedItems={selectedItems}
          />
        </>
      )}
    </div>
  );
};

export default ContractLineListing;
