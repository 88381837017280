import React, { useState, useMemo, useEffect } from 'react';
import {
  AFPTable,
  EmptyState,
  useModal,
  FlexView,
} from '@gsa/afp-component-library';
import { DELETE_ACTION, getColorColumns } from './colors-table-defs';
import AddColor from './add-color';
import DeleteColorModal from './delete-color-modal';
import { useContractLine } from '../provider/contract-line-provider';

const ColorsTab = () => {
  const [selectedColor, setSelectedColor] = useState(null);
  const { isOpen, openModal, closeModal } = useModal();

  const {
    contractLine,
    gsaColors,
    colorsTabData,
    setColorsTabData,
    setAlert,
  } = useContractLine();

  useEffect(() => {
    if (colorsTabData === null && contractLine?.modelColors?.length > 0) {
      setColorsTabData(contractLine.modelColors);
    }
  }, [contractLine?.modelColors]);

  const colors = colorsTabData || [];

  const onActionClick = (action, { original }) => {
    setSelectedColor(original);
    setAlert(null);
    if (action === DELETE_ACTION) openModal();
  };

  const columns = useMemo(() => getColorColumns(onActionClick, gsaColors));

  return (
    <FlexView column className="margin-top-6">
      <div>
        <span className="title-m-bold margin-bottom-4">Colors</span>
      </div>
      <AddColor />
      <AFPTable
        fullWidth
        columns={columns}
        data={colors}
        defaultSort={[['vendorColorName', 'asc']]}
      />
      {!colors.length && (
        <div className="table-empty-state">
          <EmptyState bottomText="Add colors to the table" />
        </div>
      )}
      {isOpen && selectedColor && (
        <DeleteColorModal color={selectedColor} onClose={closeModal} />
      )}
    </FlexView>
  );
};

ColorsTab.propTypes = {};

export default ColorsTab;
