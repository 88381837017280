/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_MAKES_BY_PARTIAL_MATCH = gql`
  query($makeName: String!, $dataSource: String) {
    getMakesByPartialMatch(makeName: $makeName, dataSource: $dataSource) {
      makeCode
      makeName
    }
  }
`;

export const GET_MAKES_BY_PARTIAL_MATCH_FROM_APPROVAL = gql`
  query($makeName: String!, $dataSource: String) {
    getMakesByPartialMatchFromApproval(
      makeName: $makeName
      dataSource: $dataSource
    ) {
      bodyMake {
        makeName
        makeCode
        approvalStatus
      }
      makeCode
    }
  }
`;

export const EDIT_MAKE_MODEL_APPROVAL_REQUEST = gql`
  mutation editMakeModelApproval(
    $editMakeModelApprovalRequestInput: MakeModelApprovalRequestEditInput!
  ) {
    editMakeModelApproval(
      editMakeModelApprovalRequestInput: $editMakeModelApprovalRequestInput
    )
  }
`;

export const GET_CHASSIS_MAKES_BY_PARTIAL_MATCH_FROM_APPROVAL = gql`
  query($makeName: String!, $dataSource: String) {
    getMakesByPartialMatchFromApproval(
      makeName: $makeName
      dataSource: $dataSource
    ) {
      chassisMakeCode
      chassisMake {
        makeName
        makeCode
        approvalStatus
      }
    }
  }
`;

export const GET_MODELS_BY_PARTIAL_MATCH_FROM_APPROVAL = gql`
  query GetModelsByPartialMatchFromApproval(
    $modelName: String!
    $makeCode: Float!
    $dataSource: String
  ) {
    getModelsByPartialMatchFromApproval(
      modelName: $modelName
      makeCode: $makeCode
      dataSource: $dataSource
    ) {
      bodyModel {
        modelCode
        modelName
      }
      modelCode
      makeCode
    }
  }
`;

export const GET_CHASSIS_MODELS_BY_PARTIAL_MATCH_FROM_APPROVAL = gql`
  query GetModelsByPartialMatchFromApproval(
    $modelName: String!
    $makeCode: Float!
    $dataSource: String
  ) {
    getModelsByPartialMatchFromApproval(
      modelName: $modelName
      makeCode: $makeCode
      dataSource: $dataSource
    ) {
      chassisModel {
        modelCode
        modelName
      }
      chassisModelCode
      chassisMakeCode
    }
  }
`;

export const GET_MODELS_BY_PARTIAL_MATCH = gql`
  query($makeCode: Int!, $modelName: String!, $dataSource: String) {
    getModelsByPartialMatch(
      makeCode: $makeCode
      modelName: $modelName
      dataSource: $dataSource
    ) {
      modelCode
      modelName
    }
  }
`;

export const GET_MAKES_AND_MODELS = gql`
  query($offset: Float, $limit: Float, $filters: [Filter!], $order: OrderBy) {
    getFilteredModels(
      offset: $offset
      limit: $limit
      filters: $filters
      order: $order
    ) {
      count
      hasMore
      rows {
        modelCode
        modelName
        makeCode
        approvedByUser
        make {
          nhtsaMakeCode
          makeName
        }
        approvalStatus
        fleetStatus
        fleetComment
        createdAt
      }
    }
  }
`;

export const GET_MAKE_MODEL_APPROVAL = gql`
  query($offset: Float, $limit: Float, $filters: [Filter!], $order: OrderBy) {
    getFilteredMakeModelApprovals(
      offset: $offset
      limit: $limit
      filters: $filters
      order: $order
    ) {
      count
      hasMore
      rows {
        bodyMake {
          makeCode
          makeName
        }
        bodyModel {
          modelCode
          modelName
        }
        chassisMake {
          makeCode
          makeName
        }
        chassisModel {
          modelCode
          modelName
        }
        makeModelApprovalId
        makeCode
        modelCode
        chassisMakeCode
        chassisModelCode
        approvedByUser
        fleetApprovalStatus
        fleetComment
        createdAt
      }
    }
  }
`;

export const APPROVE_REJECT_MAKE_MODEL = gql`
  mutation approveRejectMakeModel(
    $makeModelApprovalInput: MakeModelApprovalInput!
  ) {
    approveRejectMakeModel(makeModelApprovalInput: $makeModelApprovalInput) {
      makeModelApprovalId
      makeCode
      modelCode
      chassisMakeCode
      chassisModelCode
      fleetApprovalStatus
    }
  }
`;

export const APPROVE_REJECT_MODEL = gql`
  mutation updateModelFleetStatus(
    $modelCode: String!
    $fleetStatus: Float!
    $comment: String!
  ) {
    updateModelFleetStatus(
      modelCode: $modelCode
      fleetStatus: $fleetStatus
      comment: $comment
    ) {
      makeCode
      modelCode
      modelName
      fleetStatus
    }
  }
`;

export const GET_BID_LINES_BY_MAKE_MODEL = gql`
  query getBidLinesByMakeAndModel(
    $makeModelSearchInput: MakeModelSearchInput!
  ) {
    getBidLinesByMakeAndModel(makeModelSearchInput: $makeModelSearchInput) {
      id
      bidId
      makeCode
      modelCode
      solicitationLine {
        solicitationProgram {
          program
          solicitationId
          solicitation {
            solicitationNumber
          }
        }
      }
      createdByUser
    }
  }
`;

export const GET_BID_LINES_BY_MAKE_MODEL_FOR_APPROVAL = gql`
  query getBidLinesByMakeAndModel(
    $makeModelSearchInput: MakeModelSearchInput!
  ) {
    getBidLinesByMakeAndModel(makeModelSearchInput: $makeModelSearchInput) {
      id
      bidId
      createdByUser
      vehicleSupplierUser {
        email
      }
    }
  }
`;
