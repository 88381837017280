import { gql } from '@apollo/client';

export const GET_CONTRACT_LINE_BY_ID = gql`
  query getContractLineTemplateById($contractLineId: Float!) {
    getContractLineTemplateById(contractLineId: $contractLineId) {
      id
      participant1122Prog
      isDirect
      shipmentDays
      shipmentJustification
      scheduleLine
      modelYear
      orderStartDate
      orderEndDate
      additionalInformation
      quantity
      isPurchaseAvailable
      shipmentOrDelivery
      manufacturerModelNumber
      deliveryDate
      vin
      coFirstName
      coLastName
      coEmail
      associatedLocations {
        id
        vendorAssemblyPointId
        vendorInspectionPointId
        percentageOfDomContent
      }
      contractLineFinancialRef {
        unitPrice
        destinationCharge
        invoicePrice
        asePrice
        msrp
        estimatedFleetQuantity
        estimatedTotalQuantity
      }
      contractHeader {
        contractNumber
        contractYear
        fleetAwarded
        contractUpiid
        solicitation {
          purchaseTypeCode
          solicitationNumber
          solicitationPeriods {
            id
            startDate
            endDate
          }
        }
        vendor {
          id
          vendorName
          uniqueEntityIdentifier
        }
      }
      contractModificationRef {
        contract {
          contractNo
          contractUPiid
          vendor {
            id
            vendorName
            uniqueEntityIdentifier
          }
          contractYear
          fleetAwarded
          solicitation {
            solicitationNumber
            solicitationPeriods {
              id
              startDate
              endDate
            }
          }
        }
      }
      contractLineClarifications {
        id
        clarification
        clarificationType
      }
      standardItemId
      standardItem {
        standardItemId: id
        standardItemNumber
        title
        year
        content {
          content
        }
        tags
      }
      make {
        makeName
        makeCode
      }
      model {
        modelName
        modelCode
      }
      chassisMake {
        makeName
        makeCode
      }
      chassisModel {
        modelName
        modelCode
      }
      makeModelApproval {
        fleetApprovalStatus
      }
      contractLineEcs {
        id
        contractLineId
        siEcAssociationId
        equipmentCode
        inputType
        comply
        value
        optionType
        maxQuantity
        unitPrice
        additionalShippingDays
        contractLineEcClarification {
          id
          clarification
          clarificationType
        }
        contractLineEcRelation {
          id
          contractLineEcId
          siEcAssociationId
          equipmentCode
          relationShipCode
          conflicts
        }
      }
      contractLineEngines {
        id
        siEcAssociationId
        equipmentCode
        cylinders
        engineLitres
        engineModel
        fuelTypeCodeId
        isFuelDataUnknown
        range
        rangeElectric
        deliveryRegionType
        deliveryStates
        isMetric
        isGPTKM
        mpgs {
          id
          fuelType
          type
          value
        }
        charging {
          id
          type
          isFastChargeCapable
          fastChargeTypes {
            id
            fastChargeType
            userEnteredType
            chargeOption
          }
          bkwh
        }
        contractLineEngineClarification {
          clarification
          clarificationType
        }
      }

      modelColors {
        id
        vendorColorName: description
        vendorColorCode: colorCode
        gsaColorCodeId
        standardPremiumCode
        price
      }

      contractLineAttachments {
        id
        contractLineId
        name
        documentType
        documentMetadataId
        signedUrl
        description
        sequenceNumber

        clAttachmentMetadata {
          fileLocation
          fileMimeType
          scanStatus
        }
      }
    }
  }
`;

export const GET_EQUIPMENT_OPTIONS = gql`
  query getEquipmentOptions($standardItemId: Float!, $getActive: Boolean) {
    getEquipmentOptions(
      standardItemId: $standardItemId
      getActive: $getActive
    ) {
      id
      associationTypeCode
      associationText
      inputTypeCode
      lowerBound
      upperBound
      criteriaCode
      unitCode
      preDefinedValue
      equipmentCodeId
      equipmentCode {
        code
        category
        title
        quantityRequired
        sequence
        tags
        categoryCode {
          code
          title
          additionalProps
        }
        unitCode {
          code
          standardsCodesCR {
            code
          }
          requestStatus
        }
      }
      comments {
        comment
      }
    }
  }
`;

export const GET_GSA_CONSTANTS = gql`
  query getGsaConstants {
    gsaColors: getGSAColors {
      id
      code
      title
    }
    fuelTypes: getFuelTypes(isForBids: true) {
      id
      code
      title
    }
    states: getStates {
      stateCode
      stateName
      isoCountryCode2
    }
  }
`;

export const CREATE_CONTRACTLINE_ASSO_LOC = gql`
  mutation CreateContractLineAssociatedLocation(
    $contractLineAssociatedLocationInput: ContractLineAssociatedLocationInput!
  ) {
    createContractLineAssociatedLocation(
      contractLineAssociatedLocationInput: $contractLineAssociatedLocationInput
    ) {
      id
      vendorAssemblyPointId
      vendorInspectionPointId
      percentageOfDomContent
    }
  }
`;

export const REMOVE_CONTRACTLINE_ASSO_LOC = gql`
  mutation RemoveContractLineAssociatedLocation(
    $removeContractLineAssociatedLocationId: Float!
  ) {
    removeContractLineAssociatedLocation(
      id: $removeContractLineAssociatedLocationId
    )
  }
`;

export const SAVE_CONTRACT_LINE_BY_ID = gql`
  mutation saveContractLineById(
    $contractLineId: Float!
    $contractLineInput: ContractLineInput!
  ) {
    saveContractLineById(
      contractLineId: $contractLineId
      contractLineInput: $contractLineInput
    ) {
      scheduleLine
      shipmentDays
      shipmentJustification
      participant1122Prog
    }
  }
`;
