export const SEARCH_OPTIONS = [
  { label: 'Contract number', value: 'contractNumber' },
  { label: 'Solicitation uPIID', value: 'solicitationNumber' },
  { label: 'Solicitation name', value: 'solicitationName' },
  { label: 'Vendor name/UEI', value: 'vendorNameOrUei' },
];

export const getKeyLabel = (value) => {
  const { label } = SEARCH_OPTIONS.find((opt) => opt.value === value);
  return label;
};
