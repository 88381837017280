import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@gsa/afp-component-library';

const ReviewItemsModal = ({ closeModal, items = [], data = {} }) => {
  const NAMES = {
    openTime: 'Open time',
    isDirect: 'Direct',
    openDate: 'Open date',
    closeDate: 'Close out date',
    closeTime: 'Close out time',
  };
  return (
    <Modal
      id="review-items-modal"
      variant="large"
      className="solicitation-modal"
      onClose={closeModal}
      actions={
        <>
          <Button
            type="button"
            variant="unstyled"
            onClick={closeModal}
            label="Close"
          />
        </>
      }
    >
      <div className="modal-content">
        <h2>Review Updates</h2>

        <p>
          The following updates have been made to the contract lines listed
          below:
        </p>

        <div>
          {items.map((item) => (
            <div className="review-item-container" key={item.scheduleLine}>
              <h3>{item?.scheduleLine}</h3>
              <ul>
                {Object.entries(data).map(([key, value]) => (
                  <li>
                    {NAMES[key]} value was updated to <strong>{value}</strong>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

ReviewItemsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  items: PropTypes.arrayOf({
    scheduleLine: PropTypes.string,
  }),
  data: PropTypes.shape({
    openTime: PropTypes.string,
    openDate: PropTypes.string,
    closeDate: PropTypes.string,
    closeTime: PropTypes.string,
    isDirect: PropTypes.string,
  }),
};

ReviewItemsModal.defaultProps = {
  items: [],
  data: {},
};

export default ReviewItemsModal;
