import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  ButtonDropdown,
  ButtonDropdownItem,
  FlexView,
} from '@gsa/afp-component-library';

const ContractHeaderActions = () => {
  const history = useHistory();
  const { contractHeaderId } = useParams();

  const actionsPanelLinks = () => [
    {
      title: 'View contract lines',
      onClickHandler: () => {
        history.push(
          `/catalog/contract/contract-header/${contractHeaderId}/lines`,
        );
      },
      icon: 'arrow_forward',
    },
    {
      title: 'View contract history',
      onClickHandler: () => {},
      icon: 'arrow_forward',
    },
    {
      title: 'Manage contract modifications',
      onClickHandler: () => {},
      icon: 'edit',
    },
  ];

  const actionHandlers = {
    viewContractHistory: () => {},
  };

  return (
    <FlexView hAlignContent="right">
      <ButtonDropdown
        className="margin-right-0 margin-bottom-1"
        variant="outline"
        label="Actions"
        side="right"
      >
        {actionsPanelLinks(actionHandlers).map((link) => (
          <React.Fragment key={link.title}>
            {link ? (
              <ButtonDropdownItem
                className="text-primary hover:bg-blue-5"
                label={link.title}
                iconName={link.icon}
                iconType={link.customIcon && 'custom'}
                iconFill="#005ea2"
                onClick={link.onClickHandler}
              />
            ) : null}
          </React.Fragment>
        ))}
      </ButtonDropdown>
    </FlexView>
  );
};

export default ContractHeaderActions;
