import React, { useRef, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Spinner, FlexView, Icon, Label } from '@gsa/afp-component-library';

import './typeahead.scss';

function useClickOutside(ref, callback) {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ref, callback]);
}

const TypeAhead = ({
  label,
  placeholder,
  preLoadHelpMsg,
  noOptionsFoundMsg,
  options,
  setOptions,
  fetchOptions,
  renderOptionLabel,
  selectedOption,
  setSelectedOption,
  inputValue,
  setInputValue,
  required,
  readOnly,
  responseProcessor,
  className,
  errorMessage = null,
  onBlur,
  searchMinLength = 0,
  debounceDelay = 300,
  testId = 'typeahead-input',
  disablePaste = false,
}) => {
  const [tabPressed, setTabPressed] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    let timeoutId = null;

    if (inputValue?.trim()?.length > searchMinLength) {
      setLoading(true);
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        fetchOptions(inputValue, signal)
          .then((response) => response.json())
          .then((response) => {
            const newOptions = responseProcessor(response);
            setOptions(newOptions);
            setLoading(false);
            const isInputValueInOptions = newOptions.some(
              (option) =>
                option.label?.toLowerCase().trim() ===
                inputValue?.toLowerCase().trim(),
            );
            if (isInputValueInOptions) {
              const opt = newOptions.find(
                (option) =>
                  option.label?.toLowerCase().trim() ===
                  inputValue?.toLowerCase().trim(),
              );
              setInputValue(opt.label);
              setSelectedOption(opt);
            }
          })
          .catch(() => {
            setLoading(false);
          });
      }, debounceDelay);
    }

    // eslint-disable-next-line
    return () => {
      setLoading(false);
      controller.abort();
      clearTimeout(timeoutId);
    };
  }, [inputValue]);

  const handleInputChange = (e) => {
    setShowPrompt(true);
    setSelectedOption(null);
    setInputValue(e.target.value);
    if (e.target.value === '') {
      setOptions([]);
    }
  };

  const handleOptionSelected = (option) => {
    setInputValue(option.label);
    setSelectedOption(option);
    setShowPrompt(false);
  };

  const handleFocus = useCallback(() => {
    setIsFocused(true);
    setShowPrompt(true);
  }, []);

  const handleBlur = useCallback(
    (e) => {
      if (options?.length > 0) {
        setShowPrompt(true);
      } else {
        setShowPrompt(false);
      }
      setIsFocused(false);
      setTabPressed(false);
      onBlur?.(e);
    },
    [options, tabPressed],
  );

  const hanldlePaste = (e) => {
    if (disablePaste) {
      return;
    }
    e.preventDefault();
    const value = (e.clipboardData || window.clipboardData).getData('text');
    setInputValue(value);
  };

  const showNoOptionsFound =
    !loading &&
    inputValue?.trim('') &&
    options?.length === 0 &&
    !selectedOption;

  useClickOutside(
    inputRef,
    () => {
      setShowPrompt(false);
    },
    [],
  );

  return (
    <div className={className}>
      <div className="c-typeahead-container">
        <div
          className={`usa-form-group ${
            errorMessage ? 'usa-form-group--error' : ''
          }`}
        >
          {label && (
            <Label className="text-bold" required={required}>
              {label}
            </Label>
          )}
          {errorMessage && (
            <span className="usa-error-message">{errorMessage}</span>
          )}
          <div className="typeahead-prefix-container">
            {!isFocused && !inputValue?.trim('') && (
              <Icon iconName="search" className="prefix-icon" />
            )}
            <input
              data-testid={testId}
              ref={inputRef}
              type="text"
              className={`usa-input main_input ${
                errorMessage ? 'usa-input--error' : ''
              }`}
              value={inputValue}
              onChange={handleInputChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onPaste={hanldlePaste}
              placeholder={placeholder}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && selectedOption) {
                  handleOptionSelected(selectedOption);
                }
              }}
              readOnly={readOnly}
              required={required}
              aria-autocomplete="list"
              aria-controls="typeahead-options"
            />
            {selectedOption && selectedOption.value ? (
              <button
                type="button"
                onClick={() => {
                  setSelectedOption(null);
                  setOptions([]);
                  setInputValue('');
                  inputRef?.current?.focus();
                }}
                className="display-flex usa-button usa-button--unstyled"
                aria-label="clear"
              >
                <Icon iconName="close" className="usa-icon--size-3 text-base" />
              </button>
            ) : null}
            {showPrompt && (
              <div className="c-type-ahead-prompt">
                {loading && (
                  <FlexView vAlignContent="center">
                    <Spinner size="small" className="margin-right-1" />{' '}
                    <span>loading...</span>
                  </FlexView>
                )}
                {!loading && options?.length > 0 && (
                  <ul id="typeahead-options">
                    {options.map((option) => (
                      <li
                        tabIndex={0}
                        className={
                          selectedOption?.value === option.value
                            ? 'selected'
                            : ''
                        }
                        key={option.value}
                        role="option"
                        aria-selected={selectedOption?.value === option.value}
                        onClick={() => {
                          handleOptionSelected(option);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleOptionSelected(option);
                          }
                        }}
                      >
                        {renderOptionLabel
                          ? renderOptionLabel(option)
                          : option.label}
                      </li>
                    ))}
                  </ul>
                )}
                {showNoOptionsFound ? (
                  <div className="no-options-found">{noOptionsFoundMsg}</div>
                ) : null}
                {!inputValue || inputValue?.length < 1 ? (
                  <div>{preLoadHelpMsg}</div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

TypeAhead.propTypes = {
  responseProcessor: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  preLoadHelpMsg: PropTypes.string,
  noOptionsFoundMsg: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  setOptions: PropTypes.func,
  fetchOptions: PropTypes.func.isRequired,
  renderOptionLabel: PropTypes.func,
  selectedOption: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  setSelectedOption: PropTypes.func,
  inputValue: PropTypes.string,
  setInputValue: PropTypes.func,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  onBlur: PropTypes.func,
  searchMinLength: PropTypes.number,
  debounceDelay: PropTypes.number,
  testId: PropTypes.string,
  disablePaste: PropTypes.bool,
};

TypeAhead.defaultProps = {
  responseProcessor: (response) => {
    return response.data?.getMakesByPartialMatch?.map((make) => ({
      value: make.value,
      label: make.label,
      fleetApprovalStatus: make?.fleetStatus,
      NHTSApprovalStatus: make?.approvalStatus,
    }));
  },
  label: '',
  placeholder: '',
  preLoadHelpMsg: 'Type to search...',
  noOptionsFoundMsg: 'No options found',
  options: [],
  setOptions: () => {},
  renderOptionLabel: null,
  selectedOption: null,
  setSelectedOption: () => {},
  inputValue: '',
  setInputValue: () => {},
  required: false,
  readOnly: false,
  className: '',
  errorMessage: null,
  onBlur: () => {},
  searchMinLength: 0,
  debounceDelay: 300,
  testId: 'typeahead-input',
  disablePaste: false,
};

export default TypeAhead;
