import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import {
  ButtonDropdown,
  ButtonDropdownItem,
  FlexView,
  useModal,
} from '@gsa/afp-component-library';
import AddContractLineModal from './add-contract-line/add-contract-line-modal';
import { SIMakeModelPanellProvider } from './make-model/si-make-model-panel-provider';
import ImportContractLineModal from './import-contract-line/import-contract-line-modal';
import { AddContractLineProvider } from './add-contract-line/add-contract-line-provider';

const ContractLineActions = ({
  contractHeader,
  contractLines,
  setAlert,
  refetchLines,
}) => {
  const history = useHistory();
  const { contractHeaderId } = useParams();

  const isSop =
    contractHeader?.solicitation?.purchaseTypeCode === 'S' ||
    contractHeader?.solicitation?.purchaseTypeCode === 'P';

  const {
    isOpen: isAddContractLineModalOpen,
    openModal: setAddContractLineModalOpen,
    closeModal: setAddContractLineModalClose,
  } = useModal();

  const {
    isOpen: isImportContractLineModalOpen,
    openModal: openImportContractLineModal,
    closeModal: closeImportContractLineModal,
  } = useModal();

  const actionsPanelLinks = () => [
    {
      title: 'View contract header',
      onClickHandler: () =>
        history.push(`/catalog/contract/contract-header/${contractHeaderId}`),
      icon: 'visibility',
    },
    {
      title: 'Add contract line',
      onClickHandler: () => setAddContractLineModalOpen(true),
      icon: 'plus',
    },
    ...(isSop
      ? [
          {
            title: 'Import awarded contract lines',
            onClickHandler: openImportContractLineModal,
            icon: 'arrow_downward',
          },
        ]
      : []),
  ];

  return (
    <FlexView hAlignContent="right">
      <ButtonDropdown
        className="margin-right-0 margin-bottom-1"
        variant="outline"
        label="Actions"
        side="right"
      >
        {actionsPanelLinks().map((link) => (
          <React.Fragment key={link.title}>
            {link ? (
              <ButtonDropdownItem
                className="text-primary hover:bg-blue-5"
                label={link.title}
                iconName={link.icon}
                onClick={link.onClickHandler}
              />
            ) : null}
          </React.Fragment>
        ))}
      </ButtonDropdown>
      {isAddContractLineModalOpen && (
        <AddContractLineProvider>
          <SIMakeModelPanellProvider>
            <AddContractLineModal
              isOpen={isAddContractLineModalOpen}
              onClose={setAddContractLineModalClose}
              contractHeader={contractHeader}
              onCreateSuccess={(contractLine) => {
                setAddContractLineModalClose();
                setAlert({
                  type: 'success',
                  message: `Line item ${contractLine.scheduleLine} has been added.`,
                });
                refetchLines();
              }}
            />
          </SIMakeModelPanellProvider>
        </AddContractLineProvider>
      )}
      <ImportContractLineModal
        isOpen={isImportContractLineModalOpen}
        contractHeader={contractHeader}
        contractLines={contractLines}
        onClose={closeImportContractLineModal}
        setAlert={setAlert}
        refetchLines={refetchLines}
      />
    </FlexView>
  );
};

ContractLineActions.propTypes = {
  contractHeader: PropTypes.shape({
    solicitation: PropTypes.shape({
      purchaseTypeCode: PropTypes.string,
    }),
  }),
  contractLines: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setAlert: PropTypes.func.isRequired,
  refetchLines: PropTypes.func.isRequired,
};

ContractLineActions.defaultProps = {
  contractHeader: {},
};

export default ContractLineActions;
