import { gql } from '@apollo/client';

export const GET_CONTRACT_HEADERS_BY_CRITERIA = gql`
  query GetContractHeadersByCriteria(
    $criteria: ContractHeaderFilterCriteriaType!
    $offset: Float
    $limit: Float
    $order: OrderBy
  ) {
    getContractHeadersByCriteria(
      criteria: $criteria
      offset: $offset
      limit: $limit
      order: $order
    ) {
      rows {
        contractHeaderId
        contractNumber
        contractUpiid
        contractYear
        solicitationId
        contractEndDate
        solicitation {
          id
          solicitationUpiid
          solicitationNumber
          title
        }
        vendor {
          vendorName
          uniqueEntityIdentifier
        }
        awardedDate
        awardCancelledDate
      }
      count
    }
  }
`;

export default GET_CONTRACT_HEADERS_BY_CRITERIA;
