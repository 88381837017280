import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typeahead, FilterPanel } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { GET_MAKES_BY_PARTIAL_MATCH } from '../../queries.gql';

export const makeTypeAheadValue = (makeName, makeCode) => {
  return [makeName, makeCode].join(' - ');
};

const { useFilterPanel } = FilterPanel;

const MakeTypeAhead = ({ filter }) => {
  const {
    setFilters: setFilterPanelFilters,
    clearOneFilter,
  } = useFilterPanel();
  const { key, placeholder, ariaLabel, customFieldProps } = filter || {};
  const { inputCharNum } = customFieldProps || {};
  const [suggestions, setSuggestions] = useState([]);
  const [values, setValues] = useState([]);
  const [, setError] = useState(null);
  const [searchValue] = useState('');

  const [getMakesByPartialName] = useLazyQuery(GET_MAKES_BY_PARTIAL_MATCH, {
    onCompleted: (data) => {
      setSuggestions(data?.getMakesByPartialMatch);
      setValues(
        data.getMakesByPartialMatch.map((m) => `${m.makeName} - ${m.makeCode}`),
      );
    },
    onError: () => {
      setError('Unable to fetch make suggestions.');
    },
  });

  const fetchValues = (_, value) => {
    getMakesByPartialName({
      variables: {
        makeName: value?.split(' - ')[0],
        dataSource: 'AFP',
      },
    });
  };

  const handleTypeAheadClear = () => {
    clearOneFilter(key);
    setFilterPanelFilters({
      type: 'setOne',
      fetchNewData: false,
      filter: {
        ...filter,
        displayValue: () => '',
        value: '',
      },
    });
  };

  const handleMakeSelection = (data) => {
    const { makeName, makeCode } = data || {};
    if (makeCode) {
      setFilterPanelFilters({
        type: 'setOne',
        fetchNewData: true,
        filter: {
          ...filter,
          displayValue: () => `Make: ${makeName} Code: ${makeCode}` || '',
          value: makeCode,
        },
      });
    }
  };

  const generateCustomOption = (opt) => {
    const item = suggestions?.find((o) => o.makeCode === opt?.split(' - ')[1]);
    return (
      <>
        {item ? (
          <div className="display-flex flex-column">
            <span className="text-ink">Make: {item?.makeName}</span>
            <span className="text-ink">Code: {item?.makeCode}</span>
          </div>
        ) : (
          opt
        )}
      </>
    );
  };

  return (
    <Typeahead
      key={key}
      filterValue={searchValue}
      placeholder={placeholder}
      ariaLabel={ariaLabel}
      onOptionEnter={(value) => {
        const segs = value.split(' - ');
        const code = segs[segs.length - 1];
        handleMakeSelection(suggestions.find((m) => m.makeCode === code));
      }}
      onClear={handleTypeAheadClear}
      typeaheadValues={values}
      fetchTypeaheadValues={fetchValues}
      inputCharNum={inputCharNum}
      reset={!filter?.value}
      generateCustomOption={generateCustomOption}
      debounceDelay={500}
      promptText="Type to search for makes"
    />
  );
};

MakeTypeAhead.propTypes = {
  filter: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
    operator: PropTypes.string,
    placeholder: PropTypes.string,
    ariaLabel: PropTypes.string,
    customFieldProps: PropTypes.shape({
      inputCharNum: PropTypes.number,
      setFilters: PropTypes.func,
      currentFilters: PropTypes.shape({
        value: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string,
            operator: PropTypes.string,
            value: PropTypes.string,
          }),
        ),
      }),
    }),
  }).isRequired,
};

export default MakeTypeAhead;
