import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { AFPTable, EmptyState } from '@gsa/afp-component-library';
import { useMutation } from '@apollo/client';
import { without } from 'lodash';
import {
  getPhotoColumns,
  DELETE_ACTION,
  MARK_REP_IMG_ACTION,
} from './table-defs';
import { SET_CONTRACTLINE_ATTACHMENTS_ORDER } from './query';
import { useContractLine } from '../provider/contract-line-provider';

const ImagesTable = ({ setDocToDelete }) => {
  const {
    contractLineId,
    photos,
    loadContractLineAttachments,
  } = useContractLine();

  const [setContractLineAttachmentsOrder] = useMutation(
    SET_CONTRACTLINE_ATTACHMENTS_ORDER,
    {
      onCompleted: () => {
        loadContractLineAttachments();
      },
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      },
    },
  );

  const photosWithRepresentativeImage = useMemo(() => {
    if (!photos || photos.length === 0) return [];
    if (photos.length === 1) {
      return [{ ...photos[0], representativeImage: true }];
    }
    const sortedPhotos = photos.sort(
      (a, b) => a.sequenceNumber - b.sequenceNumber,
    );
    sortedPhotos[0] = { ...sortedPhotos[0], representativeImage: true };
    return [...sortedPhotos];
  }, [photos]);

  const onActionClick = useCallback(
    (action, { original }, rows) => {
      if (action === DELETE_ACTION) {
        setDocToDelete(original);
      }

      if (action === MARK_REP_IMG_ACTION) {
        const newRepresentativeImageId = parseInt(original.id, 10);
        const ids = rows.map((r) => parseInt(r.original.id, 10));
        const idsWithoutRepImage = without(ids, newRepresentativeImageId);
        setContractLineAttachmentsOrder({
          variables: {
            contractLineId: parseInt(contractLineId, 10),
            orderedAttachmentIds: [
              newRepresentativeImageId,
              ...idsWithoutRepImage,
            ],
          },
        });
      }
    },
    [setContractLineAttachmentsOrder, contractLineId, setDocToDelete],
  );

  const photoColumns = useMemo(() => getPhotoColumns(onActionClick), [
    onActionClick,
    getPhotoColumns,
  ]);

  return (
    <>
      <AFPTable
        fullWidth
        columns={photoColumns}
        data={photosWithRepresentativeImage}
        defaultSort={[['sequenceNumber', 'asc']]}
      />
      {!photosWithRepresentativeImage.length && (
        <div className="table-empty-state">
          <EmptyState bottomText="No pictures available. Please use the add photos button to upload pictures." />
        </div>
      )}
    </>
  );
};

ImagesTable.propTypes = {
  setDocToDelete: PropTypes.func.isRequired,
};

export default ImagesTable;
