/* eslint-disable react/prop-types */
import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import moment from 'moment';
import { emDashUnicode } from '../../../utilities/constants';

export default function getColumns({ isSop = true }) {
  return [
    {
      Header: 'Contract line',
      accessor: 'scheduleLine',
      sortable: true,
      Cell: ({ row }) => {
        const { contractHeaderId } = useParams();
        const { scheduleLine, id } = row?.original || {};
        return (
          <RouterLink
            to={`/catalog/contract/${contractHeaderId}/contract-line-template/${id}`}
            className="text-bold margin-left-1"
          >
            {scheduleLine || emDashUnicode}
          </RouterLink>
        );
      },
    },
    {
      Header: 'Body make',
      accessor: 'make.makeName',
      sortable: true,
      Cell: ({ row }) => {
        const { make } = row?.original || {};
        return `${make?.makeName || emDashUnicode}`;
      },
    },
    {
      Header: 'Body model',
      accessor: 'model.modelName',
      sortable: true,
      Cell: ({ row }) => {
        const { model } = row?.original || {};
        return `${model?.modelName || emDashUnicode}`;
      },
    },
    {
      Header: 'Chassis make',
      accessor: 'chassisMake.makeName',
      sortable: true,
      Cell: ({ row }) => {
        const { chassisMake } = row?.original || {};
        if (chassisMake?.makeName?.toLowerCase() === 'unkown') {
          return emDashUnicode;
        }
        return `${chassisMake?.makeName || emDashUnicode}`;
      },
    },
    {
      Header: 'Chassis model',
      accessor: 'chassisModel.modelName',
      sortable: true,
      Cell: ({ row }) => {
        const { chassisModel } = row?.original || {};
        return `${chassisModel?.modelName || emDashUnicode}`;
      },
    },
    ...(isSop
      ? [
          {
            Header: 'Open date/time',
            accessor: 'orderStartDate',
            sortable: true,
            Cell: ({ row }) => {
              const { orderStartDate } = row?.original || {};
              return orderStartDate
                ? moment(orderStartDate).format('YYYY-MM-DD hh:mm A')
                : emDashUnicode;
            },
          },
          {
            Header: 'Closeout date/time',
            accessor: 'orderEndDate',
            sortable: true,
            Cell: ({ row }) => {
              const { orderEndDate } = row?.original || {};
              return orderEndDate
                ? moment(orderEndDate).format('YYYY-MM-DD hh:mm A')
                : emDashUnicode;
            },
          },
          {
            Header: 'Direct',
            accessor: 'direct',
            sortable: true,
            Cell: ({ row }) => {
              const { isDirect } = row?.original || {};
              if (isDirect === 1) return 'Yes';
              if (isDirect === 0) return 'No';
              return emDashUnicode;
            },
          },
        ]
      : [
          {
            Header: 'Manufacturer model number',
            accessor: 'manufacturerModelNumber',
            sortable: true,
            Cell: ({ row }) => {
              const { manufacturerModelNumber } = row?.original || {};
              return manufacturerModelNumber || emDashUnicode;
            },
          },
          {
            Header: 'Make available for Ordering',
            accessor: 'makeAvailableForOrdering',
            sortable: true,
            Cell: ({ row }) => {
              const { orderEndDate, orderStartDate } = row?.original || {};
              if (orderEndDate && orderStartDate) {
                const startDate = new Date(orderStartDate);
                const endDate = new Date(orderEndDate);
                const currentDate = new Date();

                return startDate <= currentDate && currentDate <= endDate
                  ? 'Yes'
                  : 'No';
              }
              return emDashUnicode;
            },
          },
        ]),
  ];
}
